.nav {
  &__item {
    display: inline-block;
    padding: 1rem 0;
    position: relative;
    @include text-button();
    @include link-underline('transparent', 'black', 'grey-dark');

    &--active {
      @include link-underline-colours('black', 'grey-dark', 'grey-light');

      @include fonts-loaded() {
        font-family: var(--roboto-black);
      }
    }

    &--shop {
      padding: 0;
      opacity: 0.999;
      @include w-c-o();
      transition: opacity 0.3s ease-out;

      .nav--active--mobile &,
      .nav--active--filter & {
        opacity: 0.001;
        pointer-events: none;
      }

      &:after {
        width: 100%;
      }

      @media screen and (min-width: $nav) {
        display: none;
      }
    }
  }

  &-wrapper {
    @media screen and (max-width: $nav - 1) {
      z-index: 20;
      width: 100vw;
      height: calc(100vh - 8rem);
      overflow: auto;
      position: fixed;
      top: 8rem;
      left: 0;
      padding: 2rem $gutter-landscape;
      background: var(--white);
      clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
      visibility: hidden;
      opacity: 0;
      will-change: clip-path;
      @include pseudo-after();

      .nav--active--mobile &,
      .nav--active--filter & {
        visibility: visible;
        opacity: 1;
      }

      &:after {
        position: fixed;
        background-image: linear-gradient(0deg, var(--transparent), var(--white));
        width: 100%;
        height: 1rem;
        top: 8rem;
        left: 0;
      }
    }

    @media screen and (max-width: $landscape - 1) {
      padding: 2rem $gutter-portrait;
    }

    @media screen and (max-width: $portrait - 1) {
      padding: 2rem $gutter-mobile;
    }
  }

  &--primary,
  &--country,
  &--filters {
    @media screen and (max-width: $nav - 1) {
      @include pseudo-before();
      position: relative;

      &:before {
        width: 100%;
        position: absolute;
        left: 0;
      }
    }
  }

  &--primary,
  &--country {
    @media screen and (max-width: $nav - 1) {
      padding: 3rem 0;
    }
  }

  &--primary {
    @media screen and (max-width: $nav - 1) {
      @include flex-col();
      align-items: flex-start;
      margin-bottom: 2rem;

      &:before {
        bottom: 0;
        border-bottom: 0.1rem solid var(--grey-light);
      }

      ul {
        padding: 1rem 0;
      }

      li:not(:last-of-type) {
        margin-bottom: 4rem;
      }

      .nav__item {
        padding: 1.3rem 0;
        background-position:
          0 calc(100% - 1.05rem),
          0 calc(100% - 1.05rem),
          0 calc(100% - 1.05rem);

        &:before,
        &:after {
          content: none;
        }

        @include rtl() {
          background-position:
            right 0 top calc(100% - 1.05rem),
            right 0 top calc(100% - 1.05rem),
            right 0 top calc(100% - 1.05rem);
        }
      }

      & + .nav--country {
        margin-top: -2rem;

        &:before {
          content: none;
        }
      }
    }
  }

  &--country {
    padding-bottom: 0;

    @media screen and (max-width: $nav - 1) {
      margin-top: 2rem;

      &:before {
        top: 0;
        border-top: 0.1rem solid var(--grey-light);
      }
    }

    a,
    button {
      @extend .nav__item;
    }

    a {
      @include link-underline('transparent', 'black', 'grey-dark', 0.8rem, calc(100% - 4rem), 2rem);
      display: inline-block;
      padding: 0 2rem;
      margin-left: -2rem;
    }

    button {
      padding-right: 3rem;
      position: relative;
      z-index: 5;
      letter-spacing: 0.1rem;
      @include link-underline('transparent', 'black', 'grey-dark', 0.8rem, calc(100% - 3rem));

      &:before {
        content: none;
      }

      &:after {
        @include chevron('white');
        opacity: 0.999;
        border: none;
        right: 1rem;
        position: absolute;
      }

      &:active:after {
        opacity: 0.999;
      }

      @include rtl() {
        @include switch-pad(3, 0);

        &:after {
          @include switch-rtl(1);
        }
      }
    }

    .subnav {
      padding: 1rem 0;
      grid-template-columns: repeat(4, auto);
      opacity: 0.001;
      position: relative;
      @include pseudo-before();
      @include hwa();
      display: none;
      transform: scale(0);
      transform-origin: 0 0;

      @media screen and (min-width: $landscape) {
        display: block;
        transform-origin: 50% 0;
      }

      &:before {
        background: var(--white);
        width: calc(100% + 4rem);
        height: 100%;
        top: 0;
        left: -3rem;
      }

      li {
        position: relative;
        margin: 0;
        padding: 0;
        white-space: nowrap;
      }
    }

    &.nav--active--country {
      button:after {
        transform: rotate(180deg);
      }
    }
  }

  &--filters {
    @media screen and (max-width: $nav - 1) {
      &:before {
        top: 0;
        border-top: 0.1rem solid var(--grey-dark);
      }
    }
  }

  &--social,
  &--colour {
    ul {
      display: flex;
      margin-left: -1rem;

      @include rtl() {
        @include switch-mar(0, -1);
      }
    }
  }

  &--pagination {
    display: flex;
    align-items: center;
    justify-content: center;

    ul {
      display: flex;
      align-items: center;
    }

    &__page {
      @include text-button();
      @include icon-button();
      text-indent: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--grey-dark);

      @include hover() {
        color: var(--black);
      }

      &:active {
        transform: scale(0.95);
      }

      &--active {
        color: var(--black);

        @include hover() {
          color: var(--grey-dark);
        }
      }
    }
  }

  &--arrow {
    margin-top: 4rem;

    @media screen and (min-width: $landscape) {
      margin-top: 5rem;
    }

    .h5 {
      margin-bottom: 1rem;
    }

    p {
      @include fonts-loaded() {
        font-family: var(--roboto-medium);
      }
    }

    &__button {
      text-indent: -99999rem;
      position: relative;
      display: block;
      height: 4.4rem;
      width: 17.3rem;
      @include pseudo-before();
      transition: transform 0.2s ease-out;
      @include w-c-t();

      @include hover() {
        transition-duration: 0.05s;
        transform: translateX(0.5rem);
      }

      &:active {
        opacity: 0.75;
      }

      &:before {
        @include absolute-cover();
        background: url('./../images/global/icon-arrow.svg') right center no-repeat;
        background-size: 17.3rem auto;
      }

      &--prev {
        @include hover() {
          transform: translateX(-0.5rem);
        }

        &:before {
          transform: rotate(180deg);
          width: 2.3rem;
        }
      }

      @include rtl() {
        transform: scaleX(-1);

        @include hover() {
          transform: scaleX(-1) translateX(0.5rem);
        }

        &--prev {
          @include hover() {
            transform: scaleX(-1) translateX(-0.5rem);
          }
        }
      }
    }
  }
}
