html,
body {
  height: 100%;
  background-repeat: no-repeat;
}

body {
  &.nav--active--mobile,
  &.nav--active--filter,
  &.exit-popup-open {
    overflow: hidden;
  }
}

.wrapper {
  overflow: hidden;
}

header,
main,
footer {
  position: relative;
}

header {
  z-index: 10;
}

main {
  z-index: 5;

  .nav--active--filter & {
    z-index: 15;
  }

  > section {
    margin-bottom: 4rem;

    @media screen and (min-width: $landscape) {
      margin-bottom: 5rem;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @media screen and (min-width: $portrait) {
    position: relative;
    z-index: 5;
  }
}

footer {
  z-index: 15;
}

.inner {
  margin: 0 auto;
  min-width: $min;
  max-width: $max;
  padding: 0 #{$gutter-mobile};

  @media screen and (min-width: $portrait) {
    padding: 0 #{$gutter-portrait};
  }

  @media screen and (min-width: $landscape) {
    padding: 0 #{$gutter-landscape};
  }

  @media screen and (min-width: $desktop) {
    padding: 0 #{$gutter-desktop};
  }
}
