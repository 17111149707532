table,
th,
td {
  border: 0.1rem solid var(--grey-lighter);
}

table {
  border-collapse: collapse;
}

th,
td {
  padding: 1rem;
  text-align: left;
  vertical-align: top;
}

th {
  background: var(--black);
  color: var(--white);

  @include fonts-loaded() {
    font-family: var(--roboto-bold);
  }
}

tbody {
  tr:nth-of-type(even) {
    th,
    td {
      background: var(--grey-lighter);
    }
  }
}
