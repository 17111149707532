:root {
  --white: #ffffff;
  --grey-lighter: #f7f7f7;
  --grey-light: #d8d8d8;
  --grey-dark: #9b9b9b;
  --grey-darker: #979797;
  --grey-darkest: #757575;
  --black: #000000;
  --navy: #12131f;
  --blank: #2e2f3a;
  --red: #c32100;
  --transparent: rgba(255, 255, 255, 0);
  --roboto-black: "roboto-black", "Helvetica", Helvetica, Arial, "Lucida Grande", sans-serif;
  --roboto-bold: "roboto-bold", "Helvetica", Helvetica, Arial, "Lucida Grande", sans-serif;
  --roboto-medium: "roboto-medium", "Helvetica", Helvetica, Arial, "Lucida Grande", sans-serif;
  --roboto-regular: "roboto-regular", "Helvetica", Helvetica, Arial, "Lucida Grande", sans-serif;
  --italian: "italian-plate-no2-extrabold", "Helvetica", Helvetica, Arial, "Lucida Grande", sans-serif;
  --portrait: 800px;
  --landscape: 1100px;
  --nav: 1300px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  background-repeat: no-repeat;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
button,
select,
input,
textarea {
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

h1:focus,
h2:focus,
h3:focus,
h4:focus,
h5:focus,
h6:focus,
p:focus,
ul:focus,
button:focus,
select:focus,
input:focus,
textarea:focus {
  outline: 0;
}

@keyframes spin {
  0% {
    transform: translateY(-50%) rotate(0deg);
  }

  100% {
    transform: translateY(-50%) rotate(360deg);
  }
}

@keyframes spin-x {
  0% {
    transform: translateX(-50%) rotate(0deg);
  }

  100% {
    transform: translateX(-50%) rotate(360deg);
  }
}

@keyframes spin-y {
  0% {
    transform: translateY(-50%) rotate(0deg);
  }

  100% {
    transform: translateY(-50%) rotate(360deg);
  }
}

@font-face {
  font-family: "italian-plate-no2-extrabold";
  src: url("./../fonts/italian-plate-no2-extrabold.woff2") format("woff2"),url("./../fonts/italian-plate-no2-extrabold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "roboto-black";
  src: url("./../fonts/roboto-black.woff2") format("woff2"),url("./../fonts/roboto-black.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "roboto-bold";
  src: url("./../fonts/roboto-bold.woff2") format("woff2"),url("./../fonts/roboto-bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "roboto-medium";
  src: url("./../fonts/roboto-medium.woff2") format("woff2"),url("./../fonts/roboto-medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "roboto-regular";
  src: url("./../fonts/roboto-regular.woff2") format("woff2"),url("./../fonts/roboto-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html {
  font-size: 62.5%;
}

body *,
header *,
main *,
footer * {
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
button,
input,
select,
textarea {
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  color: var(--black);
}

body {
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fonts:not([lang=el-gr]) body {
  font-family: var(--roboto-regular);
}

.hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: .1rem;
  width: .1rem;
  margin: -0.1rem;
  padding: 0;
  border: 0;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
blockquote {
  font-weight: normal;
  text-transform: uppercase;
}

.fonts:not([lang=el-gr]) h1,
.fonts:not([lang=el-gr]) .h1,
.fonts:not([lang=el-gr]) h2,
.fonts:not([lang=el-gr]) .h2,
.fonts:not([lang=el-gr]) h3,
.fonts:not([lang=el-gr]) .h3,
.fonts:not([lang=el-gr]) h4,
.fonts:not([lang=el-gr]) .h4,
.fonts:not([lang=el-gr]) h5,
.fonts:not([lang=el-gr]) .h5,
.fonts:not([lang=el-gr]) h6,
.fonts:not([lang=el-gr]) .h6,
.fonts:not([lang=el-gr]) blockquote {
  font-family: var(--italian);
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
blockquote {
  letter-spacing: -0.025rem;
}

h1,
.h1 {
  font-size: 5.6rem;
  line-height: 1;
}

@media screen and (min-width: 1100px) {
  h1,
  .h1 {
    font-size: 8.4rem;
  }
}

.h1--article,
.h2--article {
  text-transform: none;
}

.h1--article {
  font-size: 2.6rem;
  line-height: 1.3;
}

@media screen and (min-width: 1100px) {
  .h1--article {
    font-size: 2.6rem;
  }
}

.fonts:not([lang=el-gr]) .h1--article {
  font-family: var(--roboto-medium);
}

h2,
.h2 {
  font-size: 4rem;
  line-height: 1.1;
}

@media screen and (min-width: 1100px) {
  h2,
  .h2 {
    font-size: 5.6rem;
  }
}

.h2--article {
  font-size: 1.5rem;
  line-height: 1.4;
  text-transform: uppercase;
}

@media screen and (min-width: 1100px) {
  .h2--article {
    font-size: 1.5rem;
  }
}

.fonts:not([lang=el-gr]) .h2--article {
  font-family: var(--roboto-bold);
}

h3,
.h3 {
  font-size: 2.8rem;
  line-height: 1.2;
}

h4,
.h4 {
  font-size: 2.2rem;
  line-height: 1.27;
}

h5,
.h5 {
  font-size: 1.8rem;
  line-height: 1.22;
}

h6,
.h6 {
  font-size: 1.5rem;
  line-height: 1.27;
}

p {
  line-height: 1.4;
}

em,
i {
  font-style: italic;
}

.fonts:not([lang=el-gr]) strong,
.fonts:not([lang=el-gr]) b {
  font-family: var(--roboto-bold);
}

sup,
sub {
  font-size: 75%;
}

sup {
  vertical-align: top;
}

small,
.small,
.timestamp {
  font-size: 1rem;
  line-height: 1.6;
}

.timestamp {
  display: block;
  color: var(--grey-darkest);
  text-transform: uppercase;
}

.fonts:not([lang=el-gr]) .timestamp {
  font-family: var(--roboto-medium);
}

ol {
  counter-reset: number;
  padding: 0;
}

ol li {
  list-style: none;
  counter-increment: number;
}

ol li:not(:last-of-type) {
  margin-bottom: .5rem;
}

ol li:before {
  content: counters(number, ".") ". ";
  font-weight: bold;
}

ol ol {
  margin: 1rem 0;
  padding-left: 2rem;
}

ol ol ol {
  counter-reset: letter;
}

ol ol ol li {
  counter-increment: letter;
}

ol ol ol li:before {
  content: counter(letter, lower-alpha) ") ";
}

ul li {
  list-style: none;
}

.diamond-list li {
  position: relative;
  padding-left: 2.5rem;
}

.diamond-list li:before {
  content: "";
  display: block;
  position: absolute;
}

.diamond-list li:not(:last-of-type) {
  margin-bottom: .5rem;
}

.diamond-list li:before {
  width: 1.5rem;
  height: 1.5rem;
  top: .5rem;
  left: 0;
  background: url("./../images/global/icon-umbro.svg") center center no-repeat;
  background-size: 100% auto;
}

.diamond-list ul {
  padding-top: .5rem;
}

.diamond-list ul li {
  padding-left: 1.75rem;
}

.diamond-list ul li:before {
  height: .1rem;
  width: .75rem;
  background: var(--black);
  background-size: 100% 100%;
  top: 1.1rem;
}

[dir=rtl] .diamond-list li {
  padding-left: 0rem;
  padding-right: 2.5rem;
}

[dir=rtl] .diamond-list li:before {
  left: auto;
  right: 0rem;
}

[dir=rtl] .diamond-list li ul li {
  padding-left: 0rem;
  padding-right: 1.75rem;
}

button {
  cursor: pointer;
}

blockquote {
  font-size: 3.2rem;
  line-height: 1.3;
}

::selection {
  color: var(--white);
  background-color: var(--grey-dark);
}

::-moz-selection {
  color: var(--white);
  background-color: var(--grey-dark);
}

html,
body {
  height: 100%;
  background-repeat: no-repeat;
}

body.nav--active--mobile,
body.nav--active--filter,
body.exit-popup-open {
  overflow: hidden;
}

.wrapper {
  overflow: hidden;
}

header,
main,
footer {
  position: relative;
}

header {
  z-index: 10;
}

main {
  z-index: 5;
}

.nav--active--filter main {
  z-index: 15;
}

main>section {
  margin-bottom: 4rem;
}

@media screen and (min-width: 1100px) {
  main>section {
    margin-bottom: 5rem;
  }
}

main>section:last-of-type {
  margin-bottom: 0;
}

@media screen and (min-width: 800px) {
  main {
    position: relative;
    z-index: 5;
  }
}

footer {
  z-index: 15;
}

.inner {
  margin: 0 auto;
  min-width: 320px;
  max-width: 1600px;
  padding: 0 3rem;
}

@media screen and (min-width: 800px) {
  .inner {
    padding: 0 6rem;
  }
}

@media screen and (min-width: 1100px) {
  .inner {
    padding: 0 8rem;
  }
}

@media screen and (min-width: 1400px) {
  .inner {
    padding: 0 12rem;
  }
}

picture {
  display: block;
  overflow: hidden;
}

img,
video {
  width: 100%;
  display: block;
}

figure img,
picture img,
video {
  height: 100%;
  object-fit: cover;
}

.lazyload,
.lazyloaded,
.b-lazy {
  transition: filter .5s ease,transform .5s ease;
  will-change: filter,transform;
}

.lazyload,
.lazyloading,
.b-lazy {
  filter: blur(0.5rem);
  opacity: .25;
}

.lazyloaded,
.b-loaded {
  filter: blur(0);
  opacity: .999;
}

.hover {
  overflow: hidden;
}

.hover *,
.hover~* * {
  pointer-events: none;
}

.hover a,
.hover button,
.hover~* a,
.hover~* button {
  pointer-events: auto;
}

.hover img {
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000;
  transform: translate3d(0, 0, 0);
  transition: all .15s ease;
  will-change: transform;
}

@media (hover: hover) {
  .hover:hover img {
    transform: scale(1.04) translate3d(calc(var(--x) * 0.1rem), calc(var(--y) * 0.1rem), 0);
  }
}

.icon {
  text-indent: -99999rem;
  width: 4.4rem;
  height: 4.4rem;
  display: block;
  flex-shrink: 0;
  position: relative;
  opacity: .999;
}

.icon:before {
  content: "";
  display: block;
  position: absolute;
}

.icon--disabled {
  opacity: .2;
  pointer-events: none;
}

.icon:before {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

@media (hover: hover) {
  .icon:hover {
    opacity: .75;
  }
}

.icon:active {
  transform: scale(0.95);
}

.icon--umbro {
  width: 5.6rem;
}

.icon--umbro--black:before {
  background: url("./../images/global/logo-umbro--black.svg") center center no-repeat;
  background-size: 100% auto;
}

.icon--umbro--white:before {
  background: url("./../images/global/logo-umbro--white.svg") center center no-repeat;
  background-size: 100% auto;
}

.icon--burger {
  margin-right: -1rem;
}

[dir=rtl] .icon--burger {
  margin-left: -1rem;
  margin-right: 0rem;
}

.icon--burger:before {
  content: none;
}

.icon--burger span,
.icon--burger span:before,
.icon--burger span:after {
  display: block;
  width: 2.4rem;
  height: .2rem;
  background: var(--black);
  position: absolute;
  transition: all .3s ease;
  will-change: opacity,transform;
}

.icon--burger span {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icon--burger span:before,
.icon--burger span:after {
  content: "";
  left: 0;
}

.icon--burger span:before {
  top: -0.7rem;
}

.icon--burger span:after {
  top: .7rem;
}

.nav--active--mobile .icon--burger span,
.nav--active--filter .icon--burger span {
  background: rgba(0,0,0,0);
}

.nav--active--mobile .icon--burger span:before,
.nav--active--filter .icon--burger span:before {
  transform: translateY(0.7rem) rotate(45deg);
}

.nav--active--mobile .icon--burger span:after,
.nav--active--filter .icon--burger span:after {
  transform: translateY(-0.7rem) rotate(-45deg);
}

.icon--search:before {
  will-change: opacity;
  transition: opacity .3s ease-out;
}

.icon--search:before {
  background: url("./../images/global/icon-search--black.svg") center center no-repeat;
  background-size: 2.5rem auto;
}

.icon--close:before {
  background: url("./../images/global/icon-close--black.svg") center center no-repeat;
  background-size: 2rem auto;
}

.icon--close-search:before {
  background: url("./../images/global/icon-close--grey.svg") center center no-repeat;
  background-size: 1.1rem auto;
}

.icon--twitter--black:before {
  background: url("./../images/global/logo-twitter--black.svg") center center no-repeat;
  background-size: 2rem auto;
}

.icon--twitter--white:before {
  background: url("./../images/global/logo-twitter--white.svg") center center no-repeat;
  background-size: 2rem auto;
}

.icon--facebook--black:before {
  background: url("./../images/global/logo-facebook--black.svg") center center no-repeat;
  background-size: 2rem auto;
}

.icon--facebook--white:before {
  background: url("./../images/global/logo-facebook--white.svg") center center no-repeat;
  background-size: 2rem auto;
}

.icon--instagram--black:before {
  background: url("./../images/global/logo-instagram--black.svg") center center no-repeat;
  background-size: 1.9rem auto;
}

.icon--instagram--white:before {
  background: url("./../images/global/logo-instagram--white.svg") center center no-repeat;
  background-size: 1.9rem auto;
}

.icon--youtube--black:before {
  background: url("./../images/global/logo-youtube--black.svg") center center no-repeat;
  background-size: 2.1rem auto;
}

.icon--youtube--white:before {
  background: url("./../images/global/logo-youtube--white.svg") center center no-repeat;
  background-size: 2.1rem auto;
}

.icon--whatsapp--black:before {
  background: url("./../images/global/logo-whatsapp--black.svg") center center no-repeat;
  background-size: 2rem auto;
}

.icon--whatsapp--white:before {
  background: url("./../images/global/logo-whatsapp--white.svg") center center no-repeat;
  background-size: 2rem auto;
}

.icon--chevron {
  display: inline-block;
}

.icon--chevron:after {
  content: "";
  display: block;
  position: absolute;
}

.icon--chevron:before,
.icon--chevron:after {
  width: 2.5rem;
  height: 2.5rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icon--chevron:before {
  border: .1rem solid var(--white);
  background: var(--transparent);
  transition: all .2s ease-out;
  will-change: background-color,transform;
}

.icon--chevron:after {
  background: url("./../images/global/icon-chevron--white.svg") center center no-repeat;
  background-size: .9rem auto;
  transform: translate(-50%, -50%) rotate(90deg);
}

@media (hover: hover) {
  .icon--chevron:hover {
    opacity: .999;
  }

  .icon--chevron:hover:before {
    transition-duration: .05s;
    background-color: var(--grey-dark);
    border-color: var(--grey-dark);
  }
}

.icon--chevron:active:before {
  transform: translate(-50%, -50%);
  transition: none;
  background-color: var(--grey-light);
  border-color: var(--grey-light);
}

.icon--chevron.back-to-top {
  width: 3.8rem;
  height: 3.8rem;
  position: fixed;
  bottom: 3rem;
  right: calc(3rem + 0.1rem);
  z-index: 20;
  transform: rotate(90deg);
  background: rgba(255,255,255,.95);
  opacity: 0;
  visibility: hidden;
}

.icon--chevron.back-to-top:before,
.icon--chevron.back-to-top:after {
  width: 100%;
  height: 100%;
}

.icon--chevron.back-to-top:before {
  border-color: var(--black);
  transition: background-color .2s ease-out,border-color .2s ease-out;
  will-change: background-color,border-color;
}

@media (hover: hover) {
  .icon--chevron.back-to-top:hover:before {
    border-color: var(--transparent);
  }

  .icon--chevron.back-to-top:hover:before {
    transition-duration: .05s,.05s;
    background: var(--grey-light);
  }
}

.icon--chevron.back-to-top:active:before {
  border-color: var(--transparent);
}

.icon--chevron.back-to-top:after {
  background-image: url("./../images/global/icon-chevron--black.svg");
  background-size: 1.2rem auto;
}

[dir=rtl] .icon--chevron.back-to-top {
  right: auto;
  left: calc(3rem + 0.1rem);
}

.icon--chevron.back-to-top:active {
  transform: rotate(90deg) scale(0.95);
}

.icon--prev:before,
.icon--next:before {
  background: url("./../images/global/icon-chevron--black.svg") center center no-repeat;
  background-size: 1.2rem auto;
}

.icon--prev:before {
  transform: translate(-50%, -50%) rotate(90deg);
}

[dir=rtl] .icon--prev:before {
  transform: translate(-50%, -50%) rotate(-90deg);
}

.icon--next:before {
  transform: translate(-50%, -50%) rotate(-90deg);
}

[dir=rtl] .icon--next:before {
  transform: translate(-50%, -50%) rotate(90deg);
}

.icon--play {
  width: 9rem;
  height: 5rem;
  background: var(--white);
}

.icon--play:before {
  background: url("./../images/global/icon-play--black.svg") center center no-repeat;
  background-size: 2.3rem auto;
}

@media (hover: hover) {
  .icon--play:hover {
    background: var(--grey-dark);
    opacity: .999;
  }

  .icon--play:hover:before {
    background-image: url("./../images/global/icon-play--white.svg");
  }
}

.icon--play:active {
  transform: none;
  background: var(--grey-light);
}

a,
button {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

a[disabled],
button[disabled] {
  color: var(--grey-light);
  border-color: var(--grey-light);
}

@media (hover: hover) {
  a[disabled]:hover,
  button[disabled]:hover {
    cursor: not-allowed;
    background-color: var(--transparent);
    color: var(--grey-light);
    border-color: var(--grey-light);
  }
}

.block-button,
.cookie .cc-deny,
.cookie .cc-allow {
  white-space: nowrap;
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  font-size: 1.4rem;
  line-height: 1.5;
  background: var(--white);
  color: var(--black);
  letter-spacing: .1rem;
  padding: 1.5rem 3rem;
  transition: all .2s ease-out;
  will-change: background-color,color,padding;
}

.block-button:before,
.cookie .cc-deny:before,
.cookie .cc-allow:before {
  content: "";
  display: block;
  position: absolute;
}

.block-button:before,
.cookie .cc-deny:before,
.cookie .cc-allow:before {
  width: 1.4rem;
  height: 1.4rem;
  opacity: .001;
  background: url("./../images/global/icon-loader--black.svg") center center no-repeat;
  background-size: 100% auto;
  top: 50%;
  transform: translateY(-50%);
  will-change: transform;
  right: 3rem;
}

[dir=rtl] .block-button:before,
[dir=rtl] .cookie .cc-deny:before,
.cookie [dir=rtl] .cc-deny:before,
[dir=rtl] .cookie .cc-allow:before,
.cookie [dir=rtl] .cc-allow:before {
  right: auto;
  left: 3.5rem;
}

@media (hover: hover) {
  .block-button:hover,
  .cookie .cc-deny:hover,
  .cookie .cc-allow:hover {
    cursor: pointer;
    transition-duration: .05s;
    background: var(--grey-dark);
    color: var(--white);
  }

  .block-button:hover:before,
  .cookie .cc-deny:hover:before,
  .cookie .cc-allow:hover:before {
    background-image: url("./../images/global/icon-loader--white.svg");
  }
}

.block-button[disabled]:before,
.cookie [disabled].cc-deny:before,
.cookie [disabled].cc-allow:before,
.block-button[disabled]:hover:before {
  background-image: url("./../images/global/icon-loader--grey.svg");
}

.block-button:active,
.cookie .cc-deny:active,
.cookie .cc-allow:active {
  transform: scale(0.98);
  transition: none;
}

.fonts:not([lang=el-gr]) .block-button,
.fonts:not([lang=el-gr]) .cookie .cc-deny,
.cookie .fonts:not([lang=el-gr]) .cc-deny,
.fonts:not([lang=el-gr]) .cookie .cc-allow,
.cookie .fonts:not([lang=el-gr]) .cc-allow {
  font-family: var(--roboto-medium);
}

.block-button--loading {
  padding-right: 6.2rem;
  cursor: not-allowed;
}

.block-button--loading:before {
  animation: spin 1.2s linear infinite;
  opacity: .999;
}

[dir=rtl] .block-button--loading {
  padding-right: 0;
  padding-left: 6.2rem;
}

.block-button--outline-black,
.cookie .cc-deny,
.block-button--outline-white {
  background: var(--transparent);
  border: solid .1rem var(--white);
}

@media (hover: hover) {
  .block-button--outline-black:hover,
  .cookie .cc-deny:hover,
  .block-button--outline-white:hover {
    border-color: var(--grey-dark);
  }
}

.block-button--outline-black,
.cookie .cc-deny {
  border-color: var(--black);
  color: var(--black);
}

.block-button--outline-white {
  color: var(--white);
}

.block-button--solid-black,
.cookie .cc-allow,
.block-button--solid-white {
  border: solid .1rem var(--white);
}

@media (hover: hover) {
  .block-button--solid-black:hover,
  .cookie .cc-allow:hover,
  .block-button--solid-white:hover {
    border-color: var(--grey-dark);
  }
}

.block-button--solid-black,
.cookie .cc-allow {
  border-color: var(--black);
  background: var(--black);
  color: var(--white);
}

.block-button--solid-white {
  background: var(--white);
}

.block-button--narrow {
  padding: 1.5rem 2rem;
}

.block-button--slim,
.cookie .cc-deny,
.cookie .cc-allow {
  font-size: 1.1rem;
  line-height: 1.36;
  padding: .8rem 1.5rem;
  position: relative;
}

.block-button--slim:before,
.cookie .cc-deny:before,
.cookie .cc-allow:before,
.block-button--slim:after,
.cookie .cc-deny:after,
.cookie .cc-allow:after {
  content: "";
  display: block;
  position: absolute;
}

.block-button--slim:before,
.cookie .cc-deny:before,
.cookie .cc-allow:before,
.block-button--slim:after,
.cookie .cc-deny:after,
.cookie .cc-allow:after {
  position: absolute;
  height: 0.5rem;
  width: 100%;
  left: 0;
}

.block-button--slim:before,
.cookie .cc-deny:before,
.cookie .cc-allow:before {
  top: -0.5rem;
}

.block-button--slim:after,
.cookie .cc-deny:after,
.cookie .cc-allow:after {
  bottom: -0.5rem;
}

.block-button--hero,
.hero--bottom-centre .hero__block .block-button,
.hero--bottom-centre .hero__block .cookie .cc-deny,
.cookie .hero--bottom-centre .hero__block .cc-deny,
.hero--bottom-centre .hero__block .cookie .cc-allow,
.cookie .hero--bottom-centre .hero__block .cc-allow,
.hero--bottom-left .hero__block .block-button,
.hero--bottom-left .hero__block .cookie .cc-deny,
.cookie .hero--bottom-left .hero__block .cc-deny,
.hero--bottom-left .hero__block .cookie .cc-allow,
.cookie .hero--bottom-left .hero__block .cc-allow,
.hero--bottom-right .hero__block .block-button,
.hero--bottom-right .hero__block .cookie .cc-deny,
.cookie .hero--bottom-right .hero__block .cc-deny,
.hero--bottom-right .hero__block .cookie .cc-allow,
.cookie .hero--bottom-right .hero__block .cc-allow {
  padding: 1rem 1.6rem;
}

.block-button--shop {
  padding: 1rem 4rem .9rem 2rem;
}

.block-button--shop:after {
  content: "";
  display: block;
  position: absolute;
}

.block-button--shop:after {
  width: 1.2rem;
  height: 1.6rem;
  background: url("./../images/global/icon-shop.svg") center center no-repeat;
  background-size: 100% auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  top: calc(50% - 0.1rem);
  right: 2rem;
}

@media screen and (min-width: 1300px) {
  .block-button--shop {
    font-size: 1.3rem;
  }
}

[dir=rtl] .block-button--shop {
  padding-left: 4rem;
  padding-right: 2rem;
}

[dir=rtl] .block-button--shop:after {
  right: auto;
  left: 2rem;
}

.back-button {
  text-indent: -99999rem;
  position: absolute;
  top: .6rem;
  left: 2.1rem;
  z-index: 5;
}

.fonts:not([lang=el-gr]) .back-button {
  font-family: var(--roboto-medium);
}

.back-button .icon {
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (min-width: 800px) {
  .back-button {
    padding: 1.2rem 0;
    text-indent: 0;
    width: auto;
    display: inline-flex;
    align-items: center;
    line-height: 4.4rem;
    margin-bottom: 1.5rem;
    display: inline;
    position: relative;
    background-image: linear-gradient(var(--grey-dark), var(--grey-dark)),linear-gradient(var(--black), var(--black)),linear-gradient(var(--transparent), var(--transparent));
    background-size: 0 .2rem,0 .2rem,calc(100% - 4rem) .2rem;
    background-position: 4rem calc(100% - 1rem),4rem calc(100% - 1rem),4rem calc(100% - 1rem);
    background-repeat: no-repeat;
    transition: background-size .075s ease-out;
    will-change: background-size;
    top: 2.1rem;
    left: 0;
  }

  .back-button:before,
  .back-button:after {
    content: "";
    display: block;
    position: absolute;
  }

  .back-button:before,
  .back-button:after {
    position: relative;
    height: 1rem;
    width: 100%;
  }
}

@media screen and (min-width: 800px)and (hover: hover) {
  .back-button:hover {
    background-size: 0 .2rem,calc(100% - 4rem) .2rem,calc(100% - 4rem) .2rem;
  }
}

@media screen and (min-width: 800px) {
  .back-button:active {
    background-size: calc(100% - 4rem) .2rem,calc(100% - 4rem) .2rem,calc(100% - 4rem) .2rem;
  }
}

@media screen and (min-width: 800px) {
  [dir=rtl] .back-button {
    background-position: right 4rem top calc(100% - 1rem),right 4rem top calc(100% - 1rem),right 4rem top calc(100% - 1rem);
  }
}

@media screen and (min-width: 800px) {
  .back-button:before,
  .back-button:after {
    content: none;
  }
}

@media screen and (min-width: 800px) {
  .product .back-button {
    top: unset;
    left: unset;
  }

  .product .back-button .icon {
    margin-bottom: 1.5rem;
  }
}

@media screen and (min-width: 800px) {
  .back-button .icon {
    position: relative;
    margin-right: -0.4rem;
  }

  .back-button .icon:before,
  .back-button .icon:after {
    transform: translateY(-50%);
    left: 0;
  }

  .back-button .icon:after,
  .back-button .icon:hover:after {
    background-image: url("./../images/global/icon-chevron--black.svg");
  }

  .back-button .icon:after {
    transform: translateY(-50%) rotate(90deg);
  }

  .back-button .icon:active:before {
    transform: translateY(-50%);
  }
}

@media screen and (min-width: 800px) {
  .back-button--black .icon:before {
    border-color: var(--black);
  }
}

@media screen and (min-width: 800px) {
  .back-button--white {
    background-image: linear-gradient(var(--grey-dark), var(--grey-dark)),linear-gradient(var(--white), var(--white)),linear-gradient(var(--transparent), var(--transparent));
  }

  .back-button--white .icon:before {
    border-color: var(--white);
  }

  .back-button--white .icon:after,
  .back-button--white .icon:hover:after {
    background-image: url("./../images/global/icon-chevron--white.svg");
  }
}

[dir=rtl] .back-button {
  left: auto;
  right: 2.1rem;
}

[dir=rtl] .back-button .icon {
  left: auto;
  right: 0rem;
  transform: scaleX(-1);
}

@media screen and (min-width: 800px) {
  [dir=rtl] .back-button {
    left: auto;
    right: 0rem;
  }

  [dir=rtl] .back-button .icon {
    margin-left: -0.4rem;
    margin-right: 0rem;
  }
}

.slider__button {
  z-index: 5;
  position: absolute;
  bottom: -0.2rem;
}

.slider__button:before,
.slider__button:after {
  width: 4rem;
  height: 4rem;
}

.slider__button:before {
  background: var(--white);
  border: 0;
}

.slider__button:after {
  background-image: url("./../images/global/icon-chevron--black.svg");
}

@media (hover: hover) {
  .slider__button:hover:after {
    background-image: url("./../images/global/icon-chevron--white.svg");
  }
}

.slider__button:active {
  transform: none;
}

.slider__button:active:after {
  background-image: url("./../images/global/icon-chevron--white.svg");
}

.slider__button--prev {
  left: -0.2rem;
}

.slider__button--next {
  right: -0.2rem;
  transform: rotate(180deg);
}

.slider__button--next:active {
  transform: rotate(180deg);
}

[dir=rtl] .slider__button--prev {
  left: auto;
  right: -0.2rem;
  transform: rotate(180deg);
}

[dir=rtl] .slider__button--prev:active {
  transform: rotate(180deg);
}

[dir=rtl] .slider__button--next {
  right: auto;
  left: -0.2rem;
  transform: rotate(0deg);
}

[dir=rtl] .slider__button--next:active {
  transform: rotate(0deg);
}

.play-button {
  position: absolute;
  bottom: 0rem;
  left: 0rem;
}

.form {
  width: 100%;
  max-width: 84rem;
}

.form-wrapper {
  width: 100%;
}

.form--grid,
.form--centre {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form--grid fieldset,
.form--grid .form-group,
.form--centre fieldset,
.form--centre .form-group {
  width: 100%;
}

.form--centre {
  max-width: 62rem;
}

.form--single {
  max-width: 52rem;
}

.form--single .form-input {
  margin-top: 1.5rem;
}

.form--single .form-input--checkbox,
.form--single .form-input--radio {
  margin-top: .5rem;
}

.form--single .block-button[type=submit],
.form--single .cookie [type=submit].cc-deny,
.cookie .form--single [type=submit].cc-deny,
.form--single .cookie [type=submit].cc-allow,
.cookie .form--single [type=submit].cc-allow {
  margin-top: .5rem;
}

legend,
label,
input,
textarea {
  display: block;
}

label,
.label {
  text-transform: uppercase;
}

.fonts:not([lang=el-gr]) label,
.fonts:not([lang=el-gr]) .label {
  font-family: var(--roboto-bold);
}

.label {
  font-size: 1.5rem;
  line-height: 1.5;
  margin-bottom: 1.5rem;
}

.label+.form-input--checkbox,
.label+.form-input--radio {
  margin-top: -1rem;
}

input:not(.filter__input),
textarea:not(.filter__input),
select:not(.filter__input) {
  border-radius: 0;
  border: .1rem solid var(--grey-light);
}

.fonts:not([lang=el-gr]) input:not(.filter__input),
.fonts:not([lang=el-gr]) textarea:not(.filter__input),
.fonts:not([lang=el-gr]) select:not(.filter__input) {
  font-family: var(--roboto-regular);
}

input:not(.filter__input).input-validation-error,
textarea:not(.filter__input).input-validation-error,
select:not(.filter__input).input-validation-error {
  border-color: var(--red);
}

input:not(.filter__input):focus,
textarea:not(.filter__input):focus,
select:not(.filter__input):focus {
  box-shadow: 0 0 .5rem var(--grey-light);
}

.form--dark input:not(.filter__input),
.form--dark textarea:not(.filter__input),
.form--dark select:not(.filter__input) {
  color: var(--white);
}

.form--dark input:not(.filter__input):not(.input-validation-error),
.form--dark textarea:not(.filter__input):not(.input-validation-error),
.form--dark select:not(.filter__input):not(.input-validation-error) {
  border-color: var(--grey-dark);
}

.form-group,
.form-single,
.form-list,
.block-button[type=submit],
.cookie [type=submit].cc-deny,
.cookie [type=submit].cc-allow {
  margin-top: 3rem;
}

@media screen and (min-width: 800px) {
  .form-group,
  .form-single,
  .block-button[type=submit],
  .cookie [type=submit].cc-deny,
  .cookie [type=submit].cc-allow {
    margin-top: 4rem;
  }
}

.form-group,
.form-centre {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 799px) {
  .form-group:first-of-type {
    margin-top: 0;
  }
}

@media screen and (min-width: 800px) {
  .form-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem;
  }

  .form-group--col-1 {
    grid-column: 1/2;
  }

  .form-group--col-2 {
    grid-column: 2/3;
  }
}

.form-list {
  width: 100%;
}

@media screen and (min-width: 800px) {
  .form-list {
    margin-top: 0;
  }

  .form-single .form-list {
    margin-top: 3rem;
  }
}

.form-single .form-list+.form-input--radio,
.form-single .form-list+.form-input--checkbox {
  margin-top: 1rem;
}

.form-single .form-list+.form-input--radio:last-of-type,
.form-single .form-list+.form-input--checkbox:last-of-type {
  margin-top: 1rem;
}

.form-input {
  position: relative;
}

.form-input--select,
.form-input--textarea,
.form-input--text,
.form-input--email,
.form-input--search,
.form-input--date,
.form-input--time,
.form-input--file,
.form-input--tel {
  margin-top: 1.5rem;
  width: 100%;
}

.form-group .form-input--select,
.form-group .form-input--textarea,
.form-group .form-input--text,
.form-group .form-input--email,
.form-group .form-input--search,
.form-group .form-input--date,
.form-group .form-input--time,
.form-group .form-input--file,
.form-group .form-input--tel {
  margin-top: 3rem;
}

@media screen and (min-width: 800px) {
  .form-group .form-input--select,
  .form-group .form-input--textarea,
  .form-group .form-input--text,
  .form-group .form-input--email,
  .form-group .form-input--search,
  .form-group .form-input--date,
  .form-group .form-input--time,
  .form-group .form-input--file,
  .form-group .form-input--tel {
    margin-top: 0;
  }
}

.form-input--select input,
.form-input--select textarea,
.form-input--select select,
.form-input--textarea input,
.form-input--textarea textarea,
.form-input--textarea select,
.form-input--text input,
.form-input--text textarea,
.form-input--text select,
.form-input--email input,
.form-input--email textarea,
.form-input--email select,
.form-input--search input,
.form-input--search textarea,
.form-input--search select,
.form-input--date input,
.form-input--date textarea,
.form-input--date select,
.form-input--time input,
.form-input--time textarea,
.form-input--time select,
.form-input--file input,
.form-input--file textarea,
.form-input--file select,
.form-input--tel input,
.form-input--tel textarea,
.form-input--tel select {
  width: 100%;
  padding: 1.4rem 1.5rem;
  font-size: 1.5rem;
  min-height: 5rem;
  line-height: 1.4;
  margin-top: 1.5rem;
}

.form-input--select select,
.form-input--textarea select,
.form-input--text select,
.form-input--email select,
.form-input--search select,
.form-input--date select,
.form-input--time select,
.form-input--file select,
.form-input--tel select {
  padding-right: 5rem;
  background: url("./../images/global/icon-chevron--black.svg") right 2.2rem center no-repeat;
  background-size: 1.3rem auto;
}

[dir=rtl] .form-input--select select,
[dir=rtl] .form-input--textarea select,
[dir=rtl] .form-input--text select,
[dir=rtl] .form-input--email select,
[dir=rtl] .form-input--search select,
[dir=rtl] .form-input--date select,
[dir=rtl] .form-input--time select,
[dir=rtl] .form-input--file select,
[dir=rtl] .form-input--tel select {
  padding-left: 5rem;
  padding-right: 1.4rem;
  background-position: 2.2rem center;
}

.form-input--select textarea,
.form-input--textarea textarea,
.form-input--text textarea,
.form-input--email textarea,
.form-input--search textarea,
.form-input--date textarea,
.form-input--time textarea,
.form-input--file textarea,
.form-input--tel textarea {
  min-height: 9.2rem;
  resize: vertical;
}

.form-input--select--multiple input,
.form-input--select--multiple textarea,
.form-input--select--multiple select,
.form-input--textarea--multiple input,
.form-input--textarea--multiple textarea,
.form-input--textarea--multiple select,
.form-input--text--multiple input,
.form-input--text--multiple textarea,
.form-input--text--multiple select,
.form-input--email--multiple input,
.form-input--email--multiple textarea,
.form-input--email--multiple select,
.form-input--search--multiple input,
.form-input--search--multiple textarea,
.form-input--search--multiple select,
.form-input--date--multiple input,
.form-input--date--multiple textarea,
.form-input--date--multiple select,
.form-input--time--multiple input,
.form-input--time--multiple textarea,
.form-input--time--multiple select,
.form-input--file--multiple input,
.form-input--file--multiple textarea,
.form-input--file--multiple select,
.form-input--tel--multiple input,
.form-input--tel--multiple textarea,
.form-input--tel--multiple select {
  width: auto;
  min-width: 9rem;
}

.form-input--select--multiple input:not(:first-of-type),
.form-input--select--multiple textarea:not(:first-of-type),
.form-input--select--multiple select:not(:first-of-type),
.form-input--textarea--multiple input:not(:first-of-type),
.form-input--textarea--multiple textarea:not(:first-of-type),
.form-input--textarea--multiple select:not(:first-of-type),
.form-input--text--multiple input:not(:first-of-type),
.form-input--text--multiple textarea:not(:first-of-type),
.form-input--text--multiple select:not(:first-of-type),
.form-input--email--multiple input:not(:first-of-type),
.form-input--email--multiple textarea:not(:first-of-type),
.form-input--email--multiple select:not(:first-of-type),
.form-input--search--multiple input:not(:first-of-type),
.form-input--search--multiple textarea:not(:first-of-type),
.form-input--search--multiple select:not(:first-of-type),
.form-input--date--multiple input:not(:first-of-type),
.form-input--date--multiple textarea:not(:first-of-type),
.form-input--date--multiple select:not(:first-of-type),
.form-input--time--multiple input:not(:first-of-type),
.form-input--time--multiple textarea:not(:first-of-type),
.form-input--time--multiple select:not(:first-of-type),
.form-input--file--multiple input:not(:first-of-type),
.form-input--file--multiple textarea:not(:first-of-type),
.form-input--file--multiple select:not(:first-of-type),
.form-input--tel--multiple input:not(:first-of-type),
.form-input--tel--multiple textarea:not(:first-of-type),
.form-input--tel--multiple select:not(:first-of-type) {
  margin-left: 1.5rem;
}

[dir=rtl] .form-input--select--multiple input:not(:first-of-type),
[dir=rtl] .form-input--select--multiple textarea:not(:first-of-type),
[dir=rtl] .form-input--select--multiple select:not(:first-of-type),
[dir=rtl] .form-input--textarea--multiple input:not(:first-of-type),
[dir=rtl] .form-input--textarea--multiple textarea:not(:first-of-type),
[dir=rtl] .form-input--textarea--multiple select:not(:first-of-type),
[dir=rtl] .form-input--text--multiple input:not(:first-of-type),
[dir=rtl] .form-input--text--multiple textarea:not(:first-of-type),
[dir=rtl] .form-input--text--multiple select:not(:first-of-type),
[dir=rtl] .form-input--email--multiple input:not(:first-of-type),
[dir=rtl] .form-input--email--multiple textarea:not(:first-of-type),
[dir=rtl] .form-input--email--multiple select:not(:first-of-type),
[dir=rtl] .form-input--search--multiple input:not(:first-of-type),
[dir=rtl] .form-input--search--multiple textarea:not(:first-of-type),
[dir=rtl] .form-input--search--multiple select:not(:first-of-type),
[dir=rtl] .form-input--date--multiple input:not(:first-of-type),
[dir=rtl] .form-input--date--multiple textarea:not(:first-of-type),
[dir=rtl] .form-input--date--multiple select:not(:first-of-type),
[dir=rtl] .form-input--time--multiple input:not(:first-of-type),
[dir=rtl] .form-input--time--multiple textarea:not(:first-of-type),
[dir=rtl] .form-input--time--multiple select:not(:first-of-type),
[dir=rtl] .form-input--file--multiple input:not(:first-of-type),
[dir=rtl] .form-input--file--multiple textarea:not(:first-of-type),
[dir=rtl] .form-input--file--multiple select:not(:first-of-type),
[dir=rtl] .form-input--tel--multiple input:not(:first-of-type),
[dir=rtl] .form-input--tel--multiple textarea:not(:first-of-type),
[dir=rtl] .form-input--tel--multiple select:not(:first-of-type) {
  margin-left: 0rem;
  margin-right: 1.5rem;
}

.form-input--select--multiple,
.form-input--file {
  display: flex;
  flex-wrap: wrap;
}

.form-input--select__label,
.form-input--file__label {
  width: 100%;
}

.form-input--select__child {
  display: none;
  flex-grow: 1;
}

.form-input--select__child--active {
  display: block;
}

.form-input--checkbox,
.form-input--radio,
.form-input--file {
  position: relative;
}

.form-input--checkbox,
.form-input--radio {
  width: auto;
  margin: 0 auto;
  display: inline-flex;
  cursor: pointer;
  flex-wrap: wrap;
  font-size: 1.2rem;
  text-transform: none;
  --border-colour: var(--grey-light);
}

.form-input--checkbox:last-of-type,
.form-input--radio:last-of-type {
  margin-top: 2rem;
}

@media screen and (min-width: 800px) {
  .form-input--checkbox:last-of-type,
  .form-input--radio:last-of-type {
    margin-top: 0;
  }
}

.form-centre .form-input--checkbox,
.form-centre .form-input--radio {
  margin-top: 1rem;
}

.form--single .form-input--checkbox,
.form-list .form-input--checkbox,
.form--single .form-input--radio,
.form-list .form-input--radio {
  display: flex;
}

.form--single .form-input--checkbox:last-of-type,
.form-list .form-input--checkbox:last-of-type,
.form--single .form-input--radio:last-of-type,
.form-list .form-input--radio:last-of-type {
  margin-top: 0;
}

.form--dark .form-input--checkbox,
.form--dark .form-input--radio {
  --border-colour: var(--grey-dark);
}

.fonts:not([lang=el-gr]) .form-input--checkbox,
.fonts:not([lang=el-gr]) .form-input--radio {
  font-family: var(--roboto-regular);
}

.form-input--checkbox input,
.form-input--radio input {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: .1rem;
  width: .1rem;
  margin: -0.1rem;
  padding: 0;
  border: 0;
}

.form-input--checkbox__indicator,
.form-input--checkbox .block-button,
.form-input--checkbox .cookie .cc-deny,
.cookie .form-input--checkbox .cc-deny,
.form-input--checkbox .cookie .cc-allow,
.cookie .form-input--checkbox .cc-allow,
.form-input--radio__indicator,
.form-input--radio .block-button,
.form-input--radio .cookie .cc-deny,
.cookie .form-input--radio .cc-deny,
.form-input--radio .cookie .cc-allow,
.cookie .form-input--radio .cc-allow {
  cursor: pointer;
}

.form-input--checkbox__label,
.form-input--radio__label {
  order: 2;
  width: calc(100% - 3.7rem);
  text-transform: none;
}

.form-input--checkbox__indicator,
.form-input--radio__indicator {
  text-indent: -99999rem;
  order: 1;
  flex-shrink: 0;
  width: 4.4rem;
  height: 4.4rem;
  display: block;
  position: relative;
}

.form-input--checkbox__indicator:before,
.form-input--checkbox__indicator:after,
.form-input--radio__indicator:before,
.form-input--radio__indicator:after {
  content: "";
  display: block;
  position: absolute;
}

.form-input--checkbox__indicator:before,
.form-input--checkbox__indicator:after,
.form-input--radio__indicator:before,
.form-input--radio__indicator:after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-input--checkbox__indicator:before,
.form-input--radio__indicator:before {
  cursor: pointer;
  box-shadow: inset 0 0 0 .1rem var(--border-colour);
}

.form-input--checkbox__indicator:after,
.form-input--radio__indicator:after {
  opacity: .001;
}

.form-input--checkbox input:checked+span:after,
.form-input--radio input:checked+span:after {
  opacity: .999;
}

.form-input--checkbox input~span,
.form-input--radio input~span {
  pointer-events: none;
}

.form-input--checkbox .input-validation-error~span:before,
.form-input--radio .input-validation-error~span:before {
  --border-colour: var(--red);
}

.form-input--checkbox .input-validation-error:checked~span:before,
.form-input--radio .input-validation-error:checked~span:before {
  --border-colour: var(--white);
}

.form-input--checkbox .field-validation-error,
.form-input--radio .field-validation-error {
  order: 3;
}

@media screen and (min-width: 800px) {
  .form-input--checkbox {
    grid-column: 1/3;
  }
}

.form-input--checkbox__label {
  padding-top: 1.3rem;
}

.form-input--checkbox__indicator {
  padding: 1.2rem;
  margin: 0 .5rem 0 -1.2rem;
}

[dir=rtl] .form-input--checkbox__indicator {
  margin-left: 0.5rem;
  margin-right: -1.2rem;
}

.form-input--checkbox__indicator:before,
.form-input--checkbox__indicator:after {
  width: 2rem;
  height: 2rem;
}

.form-input--checkbox__indicator:after {
  background: url("./../images/global/icon-tick--black.svg") center center no-repeat;
  background-size: 1.2rem auto;
}

.form--dark .form-input--checkbox__indicator:after {
  background-image: url("./../images/global/icon-tick--white.svg");
}

.form-input--radio {
  --selected-colour: var(--black);
}

.form--dark .form-input--radio {
  --selected-colour: var(--white);
}

.form-input--radio__label {
  padding-top: 1.2rem;
}

.form-input--radio__indicator {
  padding: 1.4rem;
  margin: 0 .1rem 0 -1.4rem;
}

[dir=rtl] .form-input--radio__indicator {
  margin-left: 0.1rem;
  margin-right: -1.4rem;
}

.form-input--radio__indicator:before,
.form-input--radio__indicator:after {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
}

.form-input--radio__indicator:after {
  box-shadow: inset 0 0 0 .5rem var(--selected-colour);
}

.form-input--file {
  align-self: flex-start;
  align-items: center;
}

.js .form-input--file input {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: .1rem;
  width: .1rem;
  margin: -0.1rem;
  padding: 0;
  border: 0;
}

.form-input--file .block-button,
.form-input--file .cookie .cc-deny,
.cookie .form-input--file .cc-deny,
.form-input--file .cookie .cc-allow,
.cookie .form-input--file .cc-allow,
.form-input--file__name {
  margin-top: 1.5rem;
}

.no-js .form-input--file .block-button,
.no-js .form-input--file .cookie .cc-deny,
.cookie .no-js .form-input--file .cc-deny,
.no-js .form-input--file .cookie .cc-allow,
.cookie .no-js .form-input--file .cc-allow,
.no-js .form-input--file__name {
  display: none;
}

.form-input--file .block-button,
.form-input--file .cookie .cc-deny,
.cookie .form-input--file .cc-deny,
.form-input--file .cookie .cc-allow,
.cookie .form-input--file .cc-allow {
  flex-shrink: 0;
}

.form-input--file__name {
  margin-left: 1.5rem;
  font-size: 1.2rem;
  text-transform: none;
}

.fonts:not([lang=el-gr]) .form-input--file__name {
  font-family: var(--roboto-regular);
}

[dir=rtl] .form-input--file__name {
  margin-left: 0rem;
  margin-right: 1.5rem;
}

.form-input--placeholder {
  color: var(--transparent);
}

.form-input--placeholder input,
.form-input--placeholder textarea,
.form-input--placeholder select {
  margin-top: -2rem;
}

.form-input--search {
  background: var(--white);
  color: var(--grey-dark);
  position: relative;
  margin-top: 0;
}

.form-input--search:before {
  content: "";
  display: block;
  position: absolute;
}

.form-input--search:before,
.form-input--search label {
  pointer-events: none;
}

.form-input--search input,
.form-input--search label {
  font-size: 1rem;
}

.form-input--search input {
  text-transform: uppercase;
  padding: 1.3rem 1.5rem 1.3rem 2rem;
  letter-spacing: .1rem;
  margin: 0;
}

.fonts:not([lang=el-gr]) .form-input--search input {
  font-family: var(--roboto-medium);
}

.form-input--search input:focus+label,
.form-input--search input:valid+label {
  opacity: .001;
}

.form-input--search label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 2rem;
  opacity: .999;
  transition: opacity .2s ease;
  will-change: opacity;
  text-transform: uppercase;
  letter-spacing: .1rem;
}

.form-input--search .icon--close {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  opacity: .001;
  will-change: opacity;
}

.form-input--search .icon--close-search {
  visibility: hidden;
  opacity: .001;
  will-change: opacity;
}

.results__search--active .form-input--search input,
.nav__search--active .form-input--search input {
  padding-right: 4rem;
}

.results__search--active .form-input--search .icon--close-search,
.results__search--active .form-input--search .icon--close,
.nav__search--active .form-input--search .icon--close-search,
.nav__search--active .form-input--search .icon--close {
  visibility: visible;
  opacity: .999;
}

@media (hover: hover) {
  .results__search--active .form-input--search .icon--close-search:hover,
  .results__search--active .form-input--search .icon--close:hover,
  .nav__search--active .form-input--search .icon--close-search:hover,
  .nav__search--active .form-input--search .icon--close:hover {
    opacity: .75;
  }
}

[dir=rtl] .form-input--search:before {
  left: auto;
  right: 1.8rem;
}

[dir=rtl] .form-input--search input {
  padding-left: 1.5rem;
  padding-right: 3.5rem;
}

[dir=rtl] .form-input--search label {
  left: auto;
  right: 3.5rem;
}

[dir=rtl] .form-input--search .icon--close-search,
[dir=rtl] .form-input--search .icon--close {
  right: auto;
  left: 0rem;
}

.form-input a {
  text-decoration: underline;
}

@media (hover: hover) {
  .form-input a:hover {
    text-decoration: none;
  }
}

.form-input a:active {
  text-decoration: underline;
}

::-webkit-input-placeholder {
  color: var(--grey-light);
}

footer ::-webkit-input-placeholder {
  color: var(--grey-dark);
}

::-moz-placeholder {
  color: var(--grey-light);
}

footer ::-moz-placeholder {
  color: var(--grey-dark);
}

:-ms-input-placeholder {
  color: var(--grey-light);
}

footer :-ms-input-placeholder {
  color: var(--grey-dark);
}

:-moz-placeholder {
  color: var(--grey-light);
}

footer :-moz-placeholder {
  color: var(--grey-dark);
}

.field-validation-error,
.form-validation-error {
  display: block;
}

.fonts:not([lang=el-gr]) .field-validation-error,
.fonts:not([lang=el-gr]) .form-validation-error {
  font-family: var(--roboto-regular);
}

.form-input--placeholder .field-validation-error,
.form-input--placeholder .form-validation-error {
  color: var(--black);
}

.form--dark .field-validation-error,
.form--dark .form-validation-error {
  color: var(--white);
}

.field-validation-error {
  font-size: 1rem;
  line-height: 1.5;
  text-transform: none;
  position: absolute;
  left: 0;
  bottom: -2rem;
}

[dir=rtl] .field-validation-error {
  left: auto;
  right: 0rem;
}

.form--single .field-validation-error {
  position: relative;
  bottom: unset;
  left: unset;
  margin: 1rem 0;
}

.form--single .form-input--checkbox .field-validation-error,
.form--single .form-input--radio .field-validation-error {
  margin-top: 0;
}

.form--centre .form-input--checkbox .field-validation-error,
.form--centre .form-input--radio .field-validation-error {
  bottom: -1rem;
}

.field-validation-error:before {
  content: "*";
  color: var(--red);
}

table,
th,
td {
  border: .1rem solid var(--grey-lighter);
}

table {
  border-collapse: collapse;
}

th,
td {
  padding: 1rem;
  text-align: left;
  vertical-align: top;
}

th {
  background: var(--black);
  color: var(--white);
}

.fonts:not([lang=el-gr]) th {
  font-family: var(--roboto-bold);
}

tbody tr:nth-of-type(even) th,
tbody tr:nth-of-type(even) td {
  background: var(--grey-lighter);
}

.background__video {
  opacity: .001;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  will-change: opacity;
}

.background__video--playing {
  transition: opacity .3s ease;
  opacity: .999;
}

.absolute {
  position: absolute;
}

.center-y {
  top: 50%;
  transform: translateY(-50%);
}

.right-0 {
  right: 0;
}

.right-1 {
  right: 1rem;
}

.flex {
  display: flex;
}

@media screen and (max-width: 1299px) {
  .md-row-reverse {
    flex-direction: row-reverse;
  }
}

.relative {
  position: relative;
}

.m-1 {
  margin: 1rem;
}

.mx-1 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.ml-1 {
  margin-left: 1rem;
}

.mr-1 {
  margin-right: 1rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mt-1 {
  margin-top: 1rem;
}

.p-1 {
  padding: 1rem;
}

.px-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pl-1 {
  padding-left: 1rem;
}

.pr-1 {
  padding-right: 1rem;
}

.pb-1 {
  padding-bottom: 1rem;
}

.pt-1 {
  padding-top: 1rem;
}

.m-2 {
  margin: 2rem;
}

.mx-2 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.my-2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.ml-2 {
  margin-left: 2rem;
}

.mr-2 {
  margin-right: 2rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mt-2 {
  margin-top: 2rem;
}

.p-2 {
  padding: 2rem;
}

.px-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pl-2 {
  padding-left: 2rem;
}

.pr-2 {
  padding-right: 2rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

.pt-2 {
  padding-top: 2rem;
}

.m-3 {
  margin: 3rem;
}

.mx-3 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.my-3 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.ml-3 {
  margin-left: 3rem;
}

.mr-3 {
  margin-right: 3rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.mt-3 {
  margin-top: 3rem;
}

.p-3 {
  padding: 3rem;
}

.px-3 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.py-3 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.pl-3 {
  padding-left: 3rem;
}

.pr-3 {
  padding-right: 3rem;
}

.pb-3 {
  padding-bottom: 3rem;
}

.pt-3 {
  padding-top: 3rem;
}

.m-4 {
  margin: 4rem;
}

.mx-4 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.my-4 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.ml-4 {
  margin-left: 4rem;
}

.mr-4 {
  margin-right: 4rem;
}

.mb-4 {
  margin-bottom: 4rem;
}

.mt-4 {
  margin-top: 4rem;
}

.p-4 {
  padding: 4rem;
}

.px-4 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.py-4 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.pl-4 {
  padding-left: 4rem;
}

.pr-4 {
  padding-right: 4rem;
}

.pb-4 {
  padding-bottom: 4rem;
}

.pt-4 {
  padding-top: 4rem;
}

.m-5 {
  margin: 5rem;
}

.mx-5 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.my-5 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.ml-5 {
  margin-left: 5rem;
}

.mr-5 {
  margin-right: 5rem;
}

.mb-5 {
  margin-bottom: 5rem;
}

.mt-5 {
  margin-top: 5rem;
}

.p-5 {
  padding: 5rem;
}

.px-5 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.py-5 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.pl-5 {
  padding-left: 5rem;
}

.pr-5 {
  padding-right: 5rem;
}

.pb-5 {
  padding-bottom: 5rem;
}

.pt-5 {
  padding-top: 5rem;
}

.m-6 {
  margin: 6rem;
}

.mx-6 {
  margin-left: 6rem;
  margin-right: 6rem;
}

.my-6 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.ml-6 {
  margin-left: 6rem;
}

.mr-6 {
  margin-right: 6rem;
}

.mb-6 {
  margin-bottom: 6rem;
}

.mt-6 {
  margin-top: 6rem;
}

.p-6 {
  padding: 6rem;
}

.px-6 {
  padding-left: 6rem;
  padding-right: 6rem;
}

.py-6 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.pl-6 {
  padding-left: 6rem;
}

.pr-6 {
  padding-right: 6rem;
}

.pb-6 {
  padding-bottom: 6rem;
}

.pt-6 {
  padding-top: 6rem;
}

.m-7 {
  margin: 7rem;
}

.mx-7 {
  margin-left: 7rem;
  margin-right: 7rem;
}

.my-7 {
  margin-top: 7rem;
  margin-bottom: 7rem;
}

.ml-7 {
  margin-left: 7rem;
}

.mr-7 {
  margin-right: 7rem;
}

.mb-7 {
  margin-bottom: 7rem;
}

.mt-7 {
  margin-top: 7rem;
}

.p-7 {
  padding: 7rem;
}

.px-7 {
  padding-left: 7rem;
  padding-right: 7rem;
}

.py-7 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.pl-7 {
  padding-left: 7rem;
}

.pr-7 {
  padding-right: 7rem;
}

.pb-7 {
  padding-bottom: 7rem;
}

.pt-7 {
  padding-top: 7rem;
}

.m-8 {
  margin: 8rem;
}

.mx-8 {
  margin-left: 8rem;
  margin-right: 8rem;
}

.my-8 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.ml-8 {
  margin-left: 8rem;
}

.mr-8 {
  margin-right: 8rem;
}

.mb-8 {
  margin-bottom: 8rem;
}

.mt-8 {
  margin-top: 8rem;
}

.p-8 {
  padding: 8rem;
}

.px-8 {
  padding-left: 8rem;
  padding-right: 8rem;
}

.py-8 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.pl-8 {
  padding-left: 8rem;
}

.pr-8 {
  padding-right: 8rem;
}

.pb-8 {
  padding-bottom: 8rem;
}

.pt-8 {
  padding-top: 8rem;
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.mt-auto {
  margin-top: auto;
}

.header {
  min-height: 8rem;
}

.header * {
  font-size: 1.3rem;
}

.fonts:not([lang=el-gr]) .header * {
  font-family: var(--roboto-medium);
}

.header .inner {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.8rem;
  padding-bottom: 1.8rem;
}

@media screen and (min-width: 1300px) {
  .header .inner {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.header .icon {
  z-index: 15;
}

@media screen and (max-width: 1299px) {
  .header .icon--umbro {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .header .icon--burger {
    margin-left: auto;
  }

  [dir=rtl] .header .icon--burger {
    margin-left: 0rem;
    margin-right: auto;
  }
}

@media screen and (min-width: 1300px) {
  .header {
    min-height: 9.5rem;
  }

  .header .block-button--shop,
  .header .nav--primary li {
    margin-right: 3rem;
  }

  .header .inner {
    justify-content: flex-end;
  }

  .header .nav-wrapper {
    flex-grow: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 4.6rem;
  }

  .header .nav__search {
    order: 4;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: none;
    width: 0;
    z-index: 20;
    overflow: hidden;
  }

  .header .nav--country {
    order: 3;
    position: relative;
    margin-right: 1rem;
    z-index: 5;
  }

  .header .nav--country>a {
    padding-left: 1rem;
  }

  .header .nav--country .subnav {
    position: absolute;
    top: 6rem;
    left: 0;
  }

  .header .nav--primary {
    order: 1;
    flex-grow: 1;
  }

  .header .nav--primary ul {
    display: flex;
  }

  .header .nav--primary a {
    white-space: nowrap;
    padding: 1rem 0;
  }

  .header .block-button--shop {
    order: 3;
  }

  .header .icon--umbro {
    width: 6.8rem;
    margin-right: 5rem;
  }

  .header .icon--umbro:before {
    background-size: auto 100%;
  }

  .header .icon--burger {
    display: none;
  }

  .header .icon--search {
    order: 5;
  }
}

@media screen and (min-width: 1600px) {
  .header .block-button--shop,
  .header .nav--primary li {
    margin-right: 5rem;
  }

  .header .icon--umbro {
    margin-right: 6rem;
  }

  .header .nav--country {
    margin-right: 3rem;
    z-index: 5;
  }
}

.header--sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(-10rem);
  background: rgba(255,255,255,.95);
  box-shadow: 0 .2rem .4rem 0 rgba(0,0,0,.1);
  min-height: auto;
}

.header--sticky .inner {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.header--sticky .icon--umbro {
  height: 4rem;
}

.header--sticky .back-button {
  position: relative;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  right: 0;
  color: var(--transparent);
  line-height: unset;
  background: none;
}

.header--sticky .back-button .icon {
  width: 3.8rem;
  height: 3.8rem;
}

.header--sticky .back-button .icon:before,
.header--sticky .back-button .icon:after {
  width: 100%;
  height: 100%;
}

.header--sticky .back-button .icon:before {
  border-color: var(--black);
  transition: background-color .2s ease-out,border-color .2s ease-out;
  will-change: background-color,border-color;
}

@media (hover: hover) {
  .header--sticky .back-button .icon:hover:before {
    border-color: var(--transparent);
  }

  .header--sticky .back-button .icon:hover:before {
    transition-duration: .05s,.05s;
    background: var(--grey-light);
  }
}

.header--sticky .back-button .icon:active:before {
  border-color: var(--transparent);
}

.header--sticky .back-button .icon:after {
  background-image: url("./../images/global/icon-chevron--black.svg");
  background-size: 1.2rem auto;
}

.header--sticky .block-button--shop {
  margin-left: auto;
}

@media screen and (max-width: 1299px) {
  .header--sticky {
    display: none;
  }
}

@media screen and (min-width: 1300px) {
  .header--sticky .back-button {
    width: 6.8rem;
    margin-right: 5rem;
  }

  .header--sticky .back-button .icon {
    width: 3.8rem;
    height: 3.8rem;
  }

  .header--sticky .block-button--shop {
    margin-right: 0;
  }
}

@media screen and (min-width: 1600px) {
  .header--sticky .back-button+.nav--primary {
    margin-left: 5rem;
  }
}

[dir=rtl] .header--sticky .block-button--shop {
  margin-left: 0;
  margin-right: auto;
}

@media screen and (max-width: 799px) {
  [dir=rtl] .header .icon--chevron {
    left: auto;
    right: 99999rem;
  }
}

@media screen and (min-width: 1300px) {
  [dir=rtl] .header .icon--umbro {
    margin-left: 5rem;
    margin-right: 0rem;
  }

  [dir=rtl] .header .block-button--shop,
  [dir=rtl] .header .nav--primary li {
    margin-left: 3rem;
    margin-right: 0rem;
  }

  [dir=rtl] .header .nav--country {
    margin-left: 1rem;
    margin-right: 0rem;
  }

  [dir=rtl] .header--sticky .back-button {
    margin-left: 5rem;
    margin-right: 0rem;
  }

  [dir=rtl] .header--sticky .block-button--shop {
    margin-left: 0;
  }
}

@media screen and (min-width: 1600px) {
  [dir=rtl] .header .block-button--shop,
  [dir=rtl] .header .nav--primary li {
    margin-left: 5rem;
    margin-right: 0rem;
  }

  [dir=rtl] .header .icon--umbro {
    margin-left: 6rem;
    margin-right: 0rem;
  }

  [dir=rtl] .header .nav--country {
    margin-left: 3rem;
    margin-right: 0rem;
  }
}

.nav__item,
.nav--country a,
.nav--country button {
  display: inline-block;
  padding: 1rem 0;
  position: relative;
  letter-spacing: .1rem;
  text-transform: uppercase;
  font-size: 1.3rem;
  display: inline;
  position: relative;
  background-image: linear-gradient(var(--grey-dark), var(--grey-dark)),linear-gradient(var(--black), var(--black)),linear-gradient(var(--transparent), var(--transparent));
  background-size: 0 .2rem,0 .2rem,100% .2rem;
  background-position: 0 calc(100% - 0.75rem),0 calc(100% - 0.75rem),0 calc(100% - 0.75rem);
  background-repeat: no-repeat;
  transition: background-size .075s ease-out;
  will-change: background-size;
}

.fonts:not([lang=el-gr]) .nav__item,
.fonts:not([lang=el-gr]) .nav--country a,
.nav--country .fonts:not([lang=el-gr]) a,
.fonts:not([lang=el-gr]) .nav--country button,
.nav--country .fonts:not([lang=el-gr]) button {
  font-family: var(--roboto-medium);
}

.nav__item:before,
.nav--country a:before,
.nav--country button:before,
.nav__item:after,
.nav--country a:after,
.nav--country button:after {
  content: "";
  display: block;
  position: absolute;
}

.nav__item:before,
.nav--country a:before,
.nav--country button:before,
.nav__item:after,
.nav--country a:after,
.nav--country button:after {
  position: relative;
  height: 1rem;
  width: 100%;
}

@media (hover: hover) {
  .nav__item:hover,
  .nav--country a:hover,
  .nav--country button:hover {
    background-size: 0 .2rem,100% .2rem,100% .2rem;
  }
}

.nav__item:active,
.nav--country a:active,
.nav--country button:active {
  background-size: 100% .2rem,100% .2rem,100% .2rem;
}

[dir=rtl] .nav__item,
[dir=rtl] .nav--country a,
.nav--country [dir=rtl] a,
[dir=rtl] .nav--country button,
.nav--country [dir=rtl] button {
  background-position: right 0 top calc(100% - 0.75rem),right 0 top calc(100% - 0.75rem),right 0 top calc(100% - 0.75rem);
}

.nav__item--active {
  background-image: linear-gradient(var(--grey-light), var(--grey-light)),linear-gradient(var(--grey-dark), var(--grey-dark)),linear-gradient(var(--black), var(--black));
}

.fonts:not([lang=el-gr]) .nav__item--active {
  font-family: var(--roboto-black);
}

.nav__item--shop {
  padding: 0;
  opacity: .999;
  will-change: opacity;
  transition: opacity .3s ease-out;
}

.nav--active--mobile .nav__item--shop,
.nav--active--filter .nav__item--shop {
  opacity: .001;
  pointer-events: none;
}

.nav__item--shop:after {
  width: 100%;
}

@media screen and (min-width: 1300px) {
  .nav__item--shop {
    display: none;
  }
}

@media screen and (max-width: 1299px) {
  .nav-wrapper {
    z-index: 20;
    width: 100vw;
    height: calc(100vh - 8rem);
    overflow: auto;
    position: fixed;
    top: 8rem;
    left: 0;
    padding: 2rem 8rem;
    background: var(--white);
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
    visibility: hidden;
    opacity: 0;
    will-change: clip-path;
  }

  .nav-wrapper:after {
    content: "";
    display: block;
    position: absolute;
  }

  .nav--active--mobile .nav-wrapper,
  .nav--active--filter .nav-wrapper {
    visibility: visible;
    opacity: 1;
  }

  .nav-wrapper:after {
    position: fixed;
    background-image: linear-gradient(0deg, var(--transparent), var(--white));
    width: 100%;
    height: 1rem;
    top: 8rem;
    left: 0;
  }
}

@media screen and (max-width: 1099px) {
  .nav-wrapper {
    padding: 2rem 6rem;
  }
}

@media screen and (max-width: 799px) {
  .nav-wrapper {
    padding: 2rem 3rem;
  }
}

@media screen and (max-width: 1299px) {
  .nav--primary,
  .nav--country,
  .nav--filters {
    position: relative;
  }

  .nav--primary:before,
  .nav--country:before,
  .nav--filters:before {
    content: "";
    display: block;
    position: absolute;
  }

  .nav--primary:before,
  .nav--country:before,
  .nav--filters:before {
    width: 100%;
    position: absolute;
    left: 0;
  }
}

@media screen and (max-width: 1299px) {
  .nav--primary,
  .nav--country {
    padding: 3rem 0;
  }
}

@media screen and (max-width: 1299px) {
  .nav--primary {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: flex-start;
    margin-bottom: 2rem;
  }

  .nav--primary:before {
    bottom: 0;
    border-bottom: .1rem solid var(--grey-light);
  }

  .nav--primary ul {
    padding: 1rem 0;
  }

  .nav--primary li:not(:last-of-type) {
    margin-bottom: 4rem;
  }

  .nav--primary .nav__item,
  .nav--primary .nav--country a,
  .nav--country .nav--primary a,
  .nav--primary .nav--country button,
  .nav--country .nav--primary button {
    padding: 1.3rem 0;
    background-position: 0 calc(100% - 1.05rem),0 calc(100% - 1.05rem),0 calc(100% - 1.05rem);
  }

  .nav--primary .nav__item:before,
  .nav--primary .nav--country a:before,
  .nav--country .nav--primary a:before,
  .nav--primary .nav--country button:before,
  .nav--country .nav--primary button:before,
  .nav--primary .nav__item:after,
  .nav--primary .nav--country a:after,
  .nav--country .nav--primary a:after,
  .nav--primary .nav--country button:after,
  .nav--country .nav--primary button:after {
    content: none;
  }

  [dir=rtl] .nav--primary .nav__item,
  [dir=rtl] .nav--primary .nav--country a,
  .nav--country [dir=rtl] .nav--primary a,
  [dir=rtl] .nav--primary .nav--country button,
  .nav--country [dir=rtl] .nav--primary button {
    background-position: right 0 top calc(100% - 1.05rem),right 0 top calc(100% - 1.05rem),right 0 top calc(100% - 1.05rem);
  }

  .nav--primary+.nav--country {
    margin-top: -2rem;
  }

  .nav--primary+.nav--country:before {
    content: none;
  }
}

.nav--country {
  padding-bottom: 0;
}

@media screen and (max-width: 1299px) {
  .nav--country {
    margin-top: 2rem;
  }

  .nav--country:before {
    top: 0;
    border-top: .1rem solid var(--grey-light);
  }
}

.nav--country a {
  display: inline;
  position: relative;
  background-image: linear-gradient(var(--grey-dark), var(--grey-dark)),linear-gradient(var(--black), var(--black)),linear-gradient(var(--transparent), var(--transparent));
  background-size: 0 .2rem,0 .2rem,calc(100% - 4rem) .2rem;
  background-position: 2rem calc(100% - 0.8rem),2rem calc(100% - 0.8rem),2rem calc(100% - 0.8rem);
  background-repeat: no-repeat;
  transition: background-size .075s ease-out;
  will-change: background-size;
  display: inline-block;
  padding: 0 2rem;
  margin-left: -2rem;
}

.nav--country a:before,
.nav--country a:after {
  content: "";
  display: block;
  position: absolute;
}

.nav--country a:before,
.nav--country a:after {
  position: relative;
  height: 1rem;
  width: 100%;
}

@media (hover: hover) {
  .nav--country a:hover {
    background-size: 0 .2rem,calc(100% - 4rem) .2rem,calc(100% - 4rem) .2rem;
  }
}

.nav--country a:active {
  background-size: calc(100% - 4rem) .2rem,calc(100% - 4rem) .2rem,calc(100% - 4rem) .2rem;
}

[dir=rtl] .nav--country a {
  background-position: right 2rem top calc(100% - 0.8rem),right 2rem top calc(100% - 0.8rem),right 2rem top calc(100% - 0.8rem);
}

.nav--country button {
  padding-right: 3rem;
  position: relative;
  z-index: 5;
  letter-spacing: .1rem;
  display: inline;
  position: relative;
  background-image: linear-gradient(var(--grey-dark), var(--grey-dark)),linear-gradient(var(--black), var(--black)),linear-gradient(var(--transparent), var(--transparent));
  background-size: 0 .2rem,0 .2rem,calc(100% - 3rem) .2rem;
  background-position: 0 calc(100% - 0.8rem),0 calc(100% - 0.8rem),0 calc(100% - 0.8rem);
  background-repeat: no-repeat;
  transition: background-size .075s ease-out;
  will-change: background-size;
}

.nav--country button:before,
.nav--country button:after {
  content: "";
  display: block;
  position: absolute;
}

.nav--country button:before,
.nav--country button:after {
  position: relative;
  height: 1rem;
  width: 100%;
}

@media (hover: hover) {
  .nav--country button:hover {
    background-size: 0 .2rem,calc(100% - 3rem) .2rem,calc(100% - 3rem) .2rem;
  }
}

.nav--country button:active {
  background-size: calc(100% - 3rem) .2rem,calc(100% - 3rem) .2rem,calc(100% - 3rem) .2rem;
}

[dir=rtl] .nav--country button {
  background-position: right 0 top calc(100% - 0.8rem),right 0 top calc(100% - 0.8rem),right 0 top calc(100% - 0.8rem);
}

.nav--country button:before {
  content: none;
}

.nav--country button:after {
  width: 1.5rem;
  height: .9rem;
  background: url("./../images/global/icon-chevron--black.svg") center center no-repeat;
  background-size: 100% auto;
  top: 50%;
  transform: translateY(-50%);
  transition: all .3s ease-out;
  transform-origin: 50% 25%;
  will-change: transform;
  opacity: .999;
  border: none;
  right: 1rem;
  position: absolute;
}

.nav--country button:active:after {
  opacity: .999;
}

[dir=rtl] .nav--country button {
  padding-left: 3rem;
  padding-right: 0rem;
}

[dir=rtl] .nav--country button:after {
  right: auto;
  left: 1rem;
}

.nav--country .subnav {
  padding: 1rem 0;
  grid-template-columns: repeat(4, auto);
  opacity: .001;
  position: relative;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000;
  transform: translate3d(0, 0, 0);
  display: none;
  transform: scale(0);
  transform-origin: 0 0;
}

.nav--country .subnav:before {
  content: "";
  display: block;
  position: absolute;
}

@media screen and (min-width: 1100px) {
  .nav--country .subnav {
    display: block;
    transform-origin: 50% 0;
  }
}

.nav--country .subnav:before {
  background: var(--white);
  width: calc(100% + 4rem);
  height: 100%;
  top: 0;
  left: -3rem;
}

.nav--country .subnav li {
  position: relative;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

.nav--country.nav--active--country button:after {
  transform: rotate(180deg);
}

@media screen and (max-width: 1299px) {
  .nav--filters:before {
    top: 0;
    border-top: .1rem solid var(--grey-dark);
  }
}

.nav--social ul,
.nav--colour ul {
  display: flex;
  margin-left: -1rem;
}

[dir=rtl] .nav--social ul,
[dir=rtl] .nav--colour ul {
  margin-left: 0rem;
  margin-right: -1rem;
}

.nav--pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav--pagination ul {
  display: flex;
  align-items: center;
}

.nav--pagination__page {
  letter-spacing: .1rem;
  text-transform: uppercase;
  font-size: 1.3rem;
  text-indent: -99999rem;
  width: 4.4rem;
  height: 4.4rem;
  display: block;
  text-indent: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--grey-dark);
}

.fonts:not([lang=el-gr]) .nav--pagination__page {
  font-family: var(--roboto-medium);
}

@media (hover: hover) {
  .nav--pagination__page:hover {
    color: var(--black);
  }
}

.nav--pagination__page:active {
  transform: scale(0.95);
}

.nav--pagination__page--active {
  color: var(--black);
}

@media (hover: hover) {
  .nav--pagination__page--active:hover {
    color: var(--grey-dark);
  }
}

.nav--arrow {
  margin-top: 4rem;
}

@media screen and (min-width: 1100px) {
  .nav--arrow {
    margin-top: 5rem;
  }
}

.nav--arrow .h5 {
  margin-bottom: 1rem;
}

.fonts:not([lang=el-gr]) .nav--arrow p {
  font-family: var(--roboto-medium);
}

.nav--arrow__button {
  text-indent: -99999rem;
  position: relative;
  display: block;
  height: 4.4rem;
  width: 17.3rem;
  transition: transform .2s ease-out;
  will-change: transform;
}

.nav--arrow__button:before {
  content: "";
  display: block;
  position: absolute;
}

@media (hover: hover) {
  .nav--arrow__button:hover {
    transition-duration: .05s;
    transform: translateX(0.5rem);
  }
}

.nav--arrow__button:active {
  opacity: .75;
}

.nav--arrow__button:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("./../images/global/icon-arrow.svg") right center no-repeat;
  background-size: 17.3rem auto;
}

@media (hover: hover) {
  .nav--arrow__button--prev:hover {
    transform: translateX(-0.5rem);
  }
}

.nav--arrow__button--prev:before {
  transform: rotate(180deg);
  width: 2.3rem;
}

[dir=rtl] .nav--arrow__button {
  transform: scaleX(-1);
}

@media (hover: hover) {
  [dir=rtl] .nav--arrow__button:hover {
    transform: scaleX(-1) translateX(0.5rem);
  }
}

@media (hover: hover) {
  [dir=rtl] .nav--arrow__button--prev:hover {
    transform: scaleX(-1) translateX(-0.5rem);
  }
}

.hero {
  color: var(--white);
}

.hero__image {
  background: var(--black);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero__image .lazyloaded,
.hero__image .b-loaded {
  opacity: .75;
}

.hero--black {
  color: var(--black);
}

.hero--black__image {
  background: var(--black);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero--black__image .lazyloaded,
.hero--black__image .b-loaded {
  opacity: .75;
}

.home .hero {
  color: var(--white);
}

.home .hero__image {
  background: var(--black);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.home .hero__image .lazyloaded,
.home .hero__image .b-loaded {
  opacity: 1;
}

.hero .inner {
  position: relative;
}

.hero__block {
  min-height: 40rem;
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.back-button~.hero__block {
  padding: 5.6rem 0;
}

.hero__block * {
  position: relative;
}

.hero__block h1 sup,
.hero__block h1 sub {
  font-size: 35%;
}

.fonts:not([lang=el-gr]) .hero__block p {
  font-family: var(--roboto-medium);
}

@media screen and (min-width: 800px) {
  .hero__block {
    min-height: 50rem;
  }

  .back-button~.hero__block {
    padding: 8.6rem 0;
    margin-top: -4.4rem;
  }
}

@media screen and (min-width: 1100px) {
  .hero__block {
    min-height: 60rem;
  }
}

.hero--classic .hero__block,
.hero--tall .hero__block,
.hero--bottom-centre .hero__block {
  text-align: center;
}

.hero--bottom-centre .hero__block,
.hero--bottom-left .hero__block,
.hero--bottom-right .hero__block {
  justify-content: flex-end;
}

.hero--bottom-centre .hero__block h1,
.hero--bottom-left .hero__block h1,
.hero--bottom-right .hero__block h1 {
  font-size: 2.8rem;
  line-height: 1.21;
}

.hero--bottom-centre .hero__block p,
.hero--bottom-left .hero__block p,
.hero--bottom-right .hero__block p {
  margin-top: 1.5rem;
}

.hero--bottom-centre .hero__block .block-button,
.hero--bottom-centre .hero__block .cookie .cc-deny,
.cookie .hero--bottom-centre .hero__block .cc-deny,
.hero--bottom-centre .hero__block .cookie .cc-allow,
.cookie .hero--bottom-centre .hero__block .cc-allow,
.hero--bottom-left .hero__block .block-button,
.hero--bottom-left .hero__block .cookie .cc-deny,
.cookie .hero--bottom-left .hero__block .cc-deny,
.hero--bottom-left .hero__block .cookie .cc-allow,
.cookie .hero--bottom-left .hero__block .cc-allow,
.hero--bottom-right .hero__block .block-button,
.hero--bottom-right .hero__block .cookie .cc-deny,
.cookie .hero--bottom-right .hero__block .cc-deny,
.hero--bottom-right .hero__block .cookie .cc-allow,
.cookie .hero--bottom-right .hero__block .cc-allow {
  margin-top: 2.5rem;
}

.hero--bottom-centre .hero__block-bar,
.hero--bottom-left .hero__block-bar,
.hero--bottom-right .hero__block-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,.5);
  padding: 2rem 3rem;
}

.hero--bottom-centre .hero__block-bar p,
.hero--bottom-centre .hero__block-bar .block-button,
.hero--bottom-centre .hero__block-bar .cookie .cc-deny,
.cookie .hero--bottom-centre .hero__block-bar .cc-deny,
.hero--bottom-centre .hero__block-bar .cookie .cc-allow,
.cookie .hero--bottom-centre .hero__block-bar .cc-allow,
.hero--bottom-left .hero__block-bar p,
.hero--bottom-left .hero__block-bar .block-button,
.hero--bottom-left .hero__block-bar .cookie .cc-deny,
.cookie .hero--bottom-left .hero__block-bar .cc-deny,
.hero--bottom-left .hero__block-bar .cookie .cc-allow,
.cookie .hero--bottom-left .hero__block-bar .cc-allow,
.hero--bottom-right .hero__block-bar p,
.hero--bottom-right .hero__block-bar .block-button,
.hero--bottom-right .hero__block-bar .cookie .cc-deny,
.cookie .hero--bottom-right .hero__block-bar .cc-deny,
.hero--bottom-right .hero__block-bar .cookie .cc-allow,
.cookie .hero--bottom-right .hero__block-bar .cc-allow {
  margin-top: 0;
}

.hero--bottom-centre .hero__block-bar p,
.hero--bottom-left .hero__block-bar p,
.hero--bottom-right .hero__block-bar p {
  display: none;
}

.hero--bottom-centre--bottom-right .hero__block,
.hero--bottom-left--bottom-right .hero__block,
.hero--bottom-right--bottom-right .hero__block {
  text-align: right;
}

.hero--bottom-centre--bottom-right .hero__block h1,
.hero--bottom-left--bottom-right .hero__block h1,
.hero--bottom-right--bottom-right .hero__block h1 {
  text-align: right;
}

@media screen and (min-width: 800px) {
  .hero--bottom-centre .inner,
  .hero--bottom-left .inner,
  .hero--bottom-right .inner {
    padding: 0 3rem;
  }

  .hero--bottom-centre .hero__block-bar,
  .hero--bottom-left .hero__block-bar,
  .hero--bottom-right .hero__block-bar {
    padding: 2rem 6rem;
    justify-content: flex-end;
  }

  .hero--bottom-centre .hero__block-bar p,
  .hero--bottom-left .hero__block-bar p,
  .hero--bottom-right .hero__block-bar p {
    display: block;
    margin-right: 4rem;
    text-align: right;
  }

  [dir=rtl] .hero--bottom-centre .hero__block-bar p,
  [dir=rtl] .hero--bottom-left .hero__block-bar p,
  [dir=rtl] .hero--bottom-right .hero__block-bar p {
    margin-left: 4rem;
    margin-right: 0rem;
    text-align: left;
  }
}

@media screen and (min-width: 1100px) {
  .hero--bottom-centre .inner,
  .hero--bottom-left .inner,
  .hero--bottom-right .inner {
    padding: 0 4rem;
  }

  .hero--bottom-centre .hero__block,
  .hero--bottom-left .hero__block,
  .hero--bottom-right .hero__block {
    padding: 4rem 0;
  }

  .hero--bottom-centre .hero__block h1,
  .hero--bottom-left .hero__block h1,
  .hero--bottom-right .hero__block h1 {
    font-size: 4.4rem;
    line-height: 1.14;
  }

  .hero--bottom-centre .hero__block-bar,
  .hero--bottom-left .hero__block-bar,
  .hero--bottom-right .hero__block-bar {
    padding: 2rem 8rem;
  }
}

@media screen and (min-width: 1400px) {
  .hero--bottom-centre .hero__block-bar,
  .hero--bottom-left .hero__block-bar,
  .hero--bottom-right .hero__block-bar {
    padding: 2rem 12rem;
  }
}

.hero--bottom-left .hero__block {
  align-items: flex-start;
}

.hero--bottom-right .hero__block {
  align-items: flex-end;
}

.hero--classic .hero__block,
.hero--tall .hero__block {
  justify-content: center;
}

.hero--classic .hero__block h1+.block-button,
.hero--classic .hero__block .cookie h1+.cc-deny,
.cookie .hero--classic .hero__block h1+.cc-deny,
.hero--classic .hero__block .cookie h1+.cc-allow,
.cookie .hero--classic .hero__block h1+.cc-allow,
.hero--classic .hero__block p+.block-button,
.hero--classic .hero__block .cookie p+.cc-deny,
.cookie .hero--classic .hero__block p+.cc-deny,
.hero--classic .hero__block .cookie p+.cc-allow,
.cookie .hero--classic .hero__block p+.cc-allow,
.hero--tall .hero__block h1+.block-button,
.hero--tall .hero__block .cookie h1+.cc-deny,
.cookie .hero--tall .hero__block h1+.cc-deny,
.hero--tall .hero__block .cookie h1+.cc-allow,
.cookie .hero--tall .hero__block h1+.cc-allow,
.hero--tall .hero__block p+.block-button,
.hero--tall .hero__block .cookie p+.cc-deny,
.cookie .hero--tall .hero__block p+.cc-deny,
.hero--tall .hero__block .cookie p+.cc-allow,
.cookie .hero--tall .hero__block p+.cc-allow {
  margin-top: 4rem;
}

.hero--classic .hero__block p,
.hero--tall .hero__block p {
  font-size: 1.8rem;
  line-height: 1.33;
  margin-top: 2rem;
}

@media screen and (min-width: 800px) {
  .hero--classic .hero__block,
  .hero--tall .hero__block {
    padding: 6rem 0;
  }
}

@media screen and (min-width: 1100px) {
  .hero--classic .hero__block,
  .hero--tall .hero__block {
    padding: 8rem 0;
  }

  .hero--classic .hero__block h1+.block-button,
  .hero--classic .hero__block .cookie h1+.cc-deny,
  .cookie .hero--classic .hero__block h1+.cc-deny,
  .hero--classic .hero__block .cookie h1+.cc-allow,
  .cookie .hero--classic .hero__block h1+.cc-allow,
  .hero--classic .hero__block p+.block-button,
  .hero--classic .hero__block .cookie p+.cc-deny,
  .cookie .hero--classic .hero__block p+.cc-deny,
  .hero--classic .hero__block .cookie p+.cc-allow,
  .cookie .hero--classic .hero__block p+.cc-allow,
  .hero--tall .hero__block h1+.block-button,
  .hero--tall .hero__block .cookie h1+.cc-deny,
  .cookie .hero--tall .hero__block h1+.cc-deny,
  .hero--tall .hero__block .cookie h1+.cc-allow,
  .cookie .hero--tall .hero__block h1+.cc-allow,
  .hero--tall .hero__block p+.block-button,
  .hero--tall .hero__block .cookie p+.cc-deny,
  .cookie .hero--tall .hero__block p+.cc-deny,
  .hero--tall .hero__block .cookie p+.cc-allow,
  .cookie .hero--tall .hero__block p+.cc-allow {
    margin-top: 5rem;
  }

  .hero--classic .hero__block p,
  .hero--tall .hero__block p {
    margin-top: 3rem;
  }
}

.hero--tall .hero__block {
  min-height: 55rem;
}

.hero--tall .hero__block h1 {
  font-size: 10rem;
  line-height: 1;
}

@media screen and (min-width: 800px) {
  .hero--tall .hero__block {
    min-height: 65rem;
  }
}

@media screen and (min-width: 1100px) {
  .hero--tall .hero__block {
    min-height: 75rem;
  }

  .hero--tall .hero__block h1 {
    font-size: 15rem;
  }
}

.cookie {
  position: fixed;
  bottom: calc(3rem - 0.1rem);
  left: 3rem;
  z-index: 20;
  border: .1rem solid var(--black);
  background: rgba(255,255,255,.95);
  padding: 2rem;
  max-width: calc(100vw - calc(3rem * 2) - 7rem);
  opacity: 0;
  visibility: hidden;
}

[dir=rtl] .cookie {
  left: auto;
  right: calc(3rem + 0.1rem);
}

.cookie .cc-link {
  color: var(--red);
  text-decoration: underline;
}

@media (hover: hover) {
  .cookie .cc-link:hover {
    text-decoration: none;
  }
}

.cookie .cc-link:active {
  text-decoration: underline;
}

.cookie .cc-allow {
  margin-top: 2px;
}

@media screen and (min-width: 800px) {
  .cookie .cc-allow {
    margin-top: 0;
  }
}

.cookie .cc-deny {
  margin-top: 2rem;
  margin-right: 1rem;
  background: var(--transparent);
}

.cookie *:focus {
  outline: 0;
}

.content {
  padding: 3rem 0 8rem;
}

.content__header .h1--article {
  padding-bottom: 2rem;
  position: relative;
  margin-bottom: 4rem;
}

.content__header .h1--article:before {
  content: "";
  display: block;
  position: absolute;
}

.content__header .h1--article:before {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  border-bottom: .1rem solid var(--grey-dark);
}

.content__article .timestamp {
  margin: 3rem 0;
}

@media screen and (min-width: 800px) {
  .content__header,
  .content__article {
    max-width: 84rem;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1100px) {
  .content {
    padding: 5rem 0 12rem;
  }

  .content__header .h1--article {
    margin-bottom: 5rem;
  }
}

.contact,
.submitted {
  padding: 3rem 0 8rem;
}

.contact__header .h5,
.submitted__header .h5 {
  margin-bottom: 2rem;
  display: block;
}

.contact__header .h1--article,
.submitted__header .h1--article {
  padding-bottom: 2rem;
  position: relative;
  margin-bottom: 4rem;
}

.contact__header .h1--article:before,
.submitted__header .h1--article:before {
  content: "";
  display: block;
  position: absolute;
}

.contact__header .h1--article:before,
.submitted__header .h1--article:before {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  border-bottom: .1rem solid var(--grey-dark);
}

@media screen and (min-width: 800px) {
  .contact__header,
  .contact__form,
  .submitted__header,
  .submitted__form {
    max-width: 84rem;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1100px) {
  .contact,
  .submitted {
    padding: 5rem 0 12rem;
  }

  .contact__header .h1--article,
  .submitted__header .h1--article {
    margin-bottom: 5rem;
  }
}

.contact__form .form-input,
.contact__form .block-button,
.contact__form .cookie .cc-allow,
.cookie .contact__form .cc-allow,
.contact__form .cookie .cc-deny,
.cookie .contact__form .cc-deny {
  margin-top: 4.5rem;
}

.contact__form .block-button,
.contact__form .cookie .cc-allow,
.cookie .contact__form .cc-allow,
.contact__form .cookie .cc-deny,
.cookie .contact__form .cc-deny {
  align-self: flex-start;
}

@media screen and (min-width: 800px) {
  .contact__form .form-group {
    grid-gap: 5rem 4rem;
  }

  .contact__form .form-input {
    margin: 0;
  }

  .contact__form .form-input--textarea {
    grid-column: 1/3;
  }

  .contact__form .block-button,
  .contact__form .cookie .cc-allow,
  .cookie .contact__form .cc-allow,
  .contact__form .cookie .cc-deny,
  .cookie .contact__form .cc-deny {
    margin-top: 5rem;
    margin-right: auto;
  }
}

@media screen and (min-width: 1100px) {
  .contact__header {
    padding-bottom: 4rem;
  }
}

