@font-face {
  font-family: 'italian-plate-no2-extrabold';
  src: url('./../fonts/italian-plate-no2-extrabold.woff2') format('woff2'),
       url('./../fonts/italian-plate-no2-extrabold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'roboto-black';
  src: url('./../fonts/roboto-black.woff2') format('woff2'),
       url('./../fonts/roboto-black.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'roboto-bold';
  src: url('./../fonts/roboto-bold.woff2') format('woff2'),
       url('./../fonts/roboto-bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'roboto-medium';
  src: url('./../fonts/roboto-medium.woff2') format('woff2'),
       url('./../fonts/roboto-medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'roboto-regular';
  src: url('./../fonts/roboto-regular.woff2') format('woff2'),
       url('./../fonts/roboto-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
