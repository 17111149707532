.form {
  width: 100%;
  max-width: 84rem;

  &-wrapper {
    width: 100%;
  }

  &--grid,
  &--centre {
    margin: 0 auto;
    @include flex-col();

    fieldset,
    .form-group {
      width: 100%;
    }
  }

  &--centre {
    max-width: 62rem;
  }

  &--single {
    max-width: 52rem;

    .form-input {
      margin-top: 1.5rem;

      &--checkbox,
      &--radio {
        margin-top: 0.5rem;
      }
    }

    .block-button[type="submit"] {
      margin-top: 0.5rem;
    }
  }
}

legend,
label,
input,
textarea {
  display: block;
}

label,
.label {
  text-transform: uppercase;

  @include fonts-loaded() {
    font-family: var(--roboto-bold);
  }
}

.label {
  font-size: 1.5rem;
  line-height: 1.5;
  margin-bottom: 1.5rem;

  + .form-input--checkbox,
  + .form-input--radio {
    margin-top: -1rem;
  }
}

input,
textarea,
select {
  &:not(.filter__input) {
    border-radius: 0;
    border: 0.1rem solid var(--grey-light);

    @include fonts-loaded() {
      font-family: var(--roboto-regular);
    }

    &.input-validation-error {
      border-color: var(--red);
    }

    &:focus {
      box-shadow: 0 0 0.5rem var(--grey-light);
    }

    .form--dark & {
      color: var(--white);

      &:not(.input-validation-error) {
        border-color: var(--grey-dark);
      }
    }
  }
}

.form-group,
.form-single,
.form-list,
.block-button[type="submit"] {
  margin-top: 3rem;
}

.form-group,
.form-single,
.block-button[type="submit"] {
  @media screen and (min-width: $portrait) {
    margin-top: 4rem;
  }
}

.form-group,
.form-centre {
  @include flex-col();
}

.form-group {
  @media screen and (max-width: $portrait - 1) {
    &:first-of-type {
      margin-top: 0;
    }
  }

  @media screen and (min-width: $portrait) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem;

    &--col-1 {
      grid-column: 1 / 2;
    }

    &--col-2 {
      grid-column: 2 / 3;
    }
  }
}

.form-list {
  width: 100%;

  @media screen and (min-width: $portrait) {
    margin-top: 0;

    .form-single & {
      margin-top: 3rem;
    }
  }

  .form-single & {
    & + .form-input--radio,
    & + .form-input--checkbox {
      margin-top: 1rem;

      &:last-of-type {
        margin-top: 1rem;
      }
    }
  }
}

.form-input {
  position: relative;

  &--select,
  &--textarea,
  &--text,
  &--email,
  &--search,
  &--date,
  &--time,
  &--file,
  &--tel {
    margin-top: 1.5rem;
    width: 100%;

    // .form-center &,
    .form-group & {
      margin-top: 3rem;
    }

    .form-group & {
      @media screen and (min-width: $portrait) {
        margin-top: 0;
      }
    }

    input,
    textarea,
    select {
      width: 100%;
      padding: 1.4rem 1.5rem;
      font-size: 1.5rem;
      min-height: 5rem;
      line-height: 1.4;
      margin-top: 1.5rem;
    }

    select {
      padding-right: 5rem;
      background: url('./../images/global/icon-chevron--black.svg') right 2.2rem center no-repeat;
      background-size: 1.3rem auto;

      @include rtl() {
        @include switch-pad(5, 1.4);
        background-position: 2.2rem center;
      }
    }

    textarea {
      min-height: 9.2rem;
      resize: vertical;
    }

    &--multiple {
      input,
      textarea,
      select {
        width: auto;
        min-width: 9rem;

        &:not(:first-of-type) {
          margin-left: 1.5rem;

          @include rtl() {
            @include switch-mar(0, 1.5);
          }
        }
      }
    }
  }

  &--select--multiple,
  &--file {
    display: flex;
    flex-wrap: wrap;
  }

  &--select,
  &--file {
    &__label {
      width: 100%;
    }
  }

  &--select {
    &__child {
      display: none;
      flex-grow: 1;

      &--active {
        display: block;
      }
    }
  }

  &--checkbox,
  &--radio,
  &--file {
    position: relative;
  }

  &--checkbox,
  &--radio {
    width: auto;
    margin: 0 auto;
    display: inline-flex;
    cursor: pointer;
    flex-wrap: wrap;
    font-size: 1.2rem;
    text-transform: none;
    --border-colour: var(--grey-light);

    &:last-of-type {
      margin-top: 2rem;

      @media screen and (min-width: $portrait) {
        margin-top: 0;
      }
    }

    .form-centre & {
      margin-top: 1rem;
    }

    .form--single &,
    .form-list & {
      display: flex;

      &:last-of-type {
        margin-top: 0;
      }
    }

    .form--dark & {
      --border-colour: var(--grey-dark);
    }

    @include fonts-loaded() {
      font-family: var(--roboto-regular);
    }

    input {
      @include visually-hidden();
    }

    &__indicator,
    .block-button {
      cursor: pointer;
    }

    &__label {
      order: 2;
      width: calc(100% - 3.7rem);
      text-transform: none;
    }

    &__indicator {
      text-indent: -99999rem;
      order: 1;
      flex-shrink: 0;
      @include pseudo-blocks();
      width: 4.4rem;
      height: 4.4rem;
      display: block;
      position: relative;

      &:before,
      &:after {
        @include central();
      }

      &:before {
        cursor: pointer;
        box-shadow: inset 0 0 0 0.1rem var(--border-colour);
      }

      &:after {
        opacity: 0.001;
      }
    }

    input:checked + span:after {
      opacity: 0.999;
    }

    input ~ span {
      pointer-events: none;
    }

    .input-validation-error ~ span:before {
      --border-colour: var(--red);
    }

    .input-validation-error:checked ~ span:before {
      --border-colour: var(--white);
    }

    .field-validation-error {
      order: 3;
    }
  }

  &--checkbox {
    @media screen and (min-width: $portrait) {
      grid-column: 1 / 3;
    }

    &__label {
      padding-top: 1.3rem;
    }

    &__indicator {
      padding: 1.2rem;
      margin: 0 0.5rem 0 -1.2rem;

      @include rtl() {
        @include switch-mar(0.5, -1.2);
      }

      &:before,
      &:after {
        width: 2rem;
        height: 2rem;
      }

      &:after {
        background: url('./../images/global/icon-tick--black.svg') center center no-repeat;
        background-size: 1.2rem auto;
      }

      .form--dark & {
        &:after {
          background-image: url('./../images/global/icon-tick--white.svg');
        }
      }
    }
  }

  &--radio {
    --selected-colour: var(--black);

    .form--dark & {
      --selected-colour: var(--white);
    }

    &__label {
      padding-top: 1.2rem;
    }

    &__indicator {
      padding: 1.4rem;
      margin: 0 0.1rem 0 -1.4rem;

      @include rtl() {
        @include switch-mar(0.1, -1.4);
      }

      &:before,
      &:after {
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 50%;
      }

      &:after {
        box-shadow: inset 0 0 0 0.5rem var(--selected-colour);
      }
    }
  }

  &--file {
    align-self: flex-start;
    align-items: center;

    .js & {
      input {
        @include visually-hidden();
      }
    }

    .block-button,
    &__name {
      margin-top: 1.5rem;

      .no-js & {
        display: none;
      }
    }

    .block-button {
      flex-shrink: 0;
    }

    &__name {
      margin-left: 1.5rem;
      font-size: 1.2rem;
      text-transform: none;

      @include fonts-loaded() {
        font-family: var(--roboto-regular);
      }

      @include rtl() {
        @include switch-mar(0, 1.5);
      }
    }
  }

  &--placeholder {
    color: var(--transparent);

    input,
    textarea,
    select {
      margin-top: -2rem;
    }
  }

  &--search {
    background: var(--white);
    color: var(--grey-dark);
    position: relative;
    @include pseudo-before();
    margin-top: 0;

    &:before,
    label {
      pointer-events: none;
    }

    // &:before {
    //   width: 1.1rem;
    //   height: 1.1rem;
    //   background: url('./../images/global/icon-search--grey.svg') center center no-repeat;
    //   background-size: 100% auto;
    //   position: absolute;
    //   @include central-y();
    //   left: 1.8rem;
    // }

    input,
    label {
      font-size: 1rem;
    }

    input {
      text-transform: uppercase;
      padding: 1.3rem 1.5rem 1.3rem 2rem;
      letter-spacing: 0.1rem;
      margin: 0;

      @include fonts-loaded() {
        font-family: var(--roboto-medium);
      }

      &:focus,
      &:valid {
        & + label {
          opacity: 0.001;
        }
      }
    }

    label {
      position: absolute;
      @include central-y();
      left: 2rem;
      opacity: 0.999;
      transition: opacity 0.2s ease;
      @include w-c-o();
      text-transform: uppercase;
      letter-spacing: 0.1rem;
    }

    .icon--close {
      position: absolute;
      right: 0;
      @include central-y();
      visibility: hidden;
      opacity: 0.001;
      @include w-c-o();
    }

    .icon--close-search {
      visibility: hidden;
      opacity: 0.001;
      @include w-c-o();
    }

    .results__search--active &,
    .nav__search--active & {
      input {
        padding-right: 4rem;
      }

      .icon--close-search,
      .icon--close {
        visibility: visible;
        opacity: 0.999;

        @include hover() {
          opacity: 0.75;
        }
      }
    }

    @include rtl() {
      &:before {
        @include switch-ltr(1.8);
      }

      input {
        @include switch-pad(1.5, 3.5);
      }

      label {
        @include switch-ltr(3.5);
      }

      .icon--close-search,
      .icon--close {
        @include switch-rtl(0);
      }
    }
  }

  a {
    @include underlined-link();
  }
}

::-webkit-input-placeholder {
  @include placeholder();
}

::-moz-placeholder {
  @include placeholder();
}

:-ms-input-placeholder {
  @include placeholder();
}

:-moz-placeholder {
  @include placeholder();
}

.field-validation-error,
.form-validation-error {
  display: block;

  @include fonts-loaded() {
    font-family: var(--roboto-regular);
  }

  .form-input--placeholder & {
    color: var(--black);
  }

  .form--dark & {
    color: var(--white);
  }
}

.field-validation-error {
  font-size: 1rem;
  line-height: 1.5;
  text-transform: none;
  position: absolute;
  left: 0;
  bottom: -2rem;

  @include rtl() {
    @include switch-ltr(0);
  }

  .form--single & {
    position: relative;
    bottom: unset;
    left: unset;
    margin: 1rem 0;
  }

  .form--single .form-input--checkbox &,
  .form--single .form-input--radio & {
    margin-top: 0;
  }

  .form--centre .form-input--checkbox &,
  .form--centre .form-input--radio & {
    bottom: -1rem;
  }

  &:before {
    content: '*';
    color: var(--red);
  }
}