.absolute {
    position: absolute;
}
  
.center-y {
    @include central-y();
}

.right-0 {
    right: 0;
}

.right-1 {
    right: 1rem;
}

.flex {
    display: flex;
}

@media screen and (max-width: $nav - 1) {
    .md-row-reverse {
        flex-direction: row-reverse;
    }
}

.relative {
    position: relative;
}

$spaceAmounts: (1, 2, 3, 4, 5, 6, 7, 8, auto);

@each $space in $spaceAmounts {
  @if $space==auto {
    .m-#{$space} {
      margin: #{$space};
    }

    .mx-#{$space} {
      margin-left: #{$space};
      margin-right: #{$space};
    }

    .my-#{$space} {
      margin-top: #{$space};
      margin-bottom: #{$space};

    }

    .ml-#{$space} {
      margin-left: #{$space};
    }

    .mr-#{$space} {
      margin-right: #{$space};
    }

    .mb-#{$space} {
      margin-bottom: #{$space};
    }

    .mt-#{$space} {
      margin-top: #{$space};
    }
  }

  @else {
    .m-#{$space} {
      margin: #{$space}rem;
    }

    .mx-#{$space} {
      margin-left: #{$space}rem;
      margin-right: #{$space}rem;
    }

    .my-#{$space} {
      margin-top: #{$space}rem;
      margin-bottom: #{$space}rem;

    }

    .ml-#{$space} {
      margin-left: #{$space}rem;
    }

    .mr-#{$space} {
      margin-right: #{$space}rem;
    }

    .mb-#{$space} {
      margin-bottom: #{$space}rem;
    }

    .mt-#{$space} {
      margin-top: #{$space}rem;
    }

    .p-#{$space} {
      padding: #{$space}rem;
    }

    .px-#{$space} {
      padding-left: #{$space}rem;
      padding-right: #{$space}rem;
    }

    .py-#{$space} {
      padding-top: #{$space}rem;
      padding-bottom: #{$space}rem;

    }

    .pl-#{$space} {
      padding-left: #{$space}rem;
    }

    .pr-#{$space} {
      padding-right: #{$space}rem;
    }

    .pb-#{$space} {
      padding-bottom: #{$space}rem;
    }

    .pt-#{$space} {
      padding-top: #{$space}rem;
    }
  }
}
