.contact,
.submitted {
  padding: 3rem 0 8rem;

  &__header {
    .h5 {
      margin-bottom: 2rem;
      display: block;
    }

    .h1--article {
      @include light-underline();
      margin-bottom: 4rem;
    }
  }

  @media screen and (min-width: $portrait) {
    &__header,
    &__form {
      max-width: 84rem;
      margin: 0 auto;
    }
  }

  @media screen and (min-width: $landscape) {
    padding: 5rem 0 12rem;

    &__header {
      .h1--article {
        margin-bottom: 5rem;
      }
    }
  }
}

.contact {
  &__form {
    .form-input,
    .block-button {
      margin-top: 4.5rem;
    }

    .block-button {
      align-self: flex-start;
    }
  }

  @media screen and (min-width: $portrait) {
    &__form {
      .form-group {
        grid-gap: 5rem 4rem;
      }

      .form-input {
        margin: 0;

        &--textarea {
          grid-column: 1 / 3;
        }
      }

      .block-button {
        margin-top: 5rem;
        margin-right: auto;
      }
    }
  }

  @media screen and (min-width: $landscape) {
    &__header {
      padding-bottom: 4rem;
    }
  }
}
