.cookie {
  position: fixed;
  bottom: calc(#{$gutter-mobile} - 0.1rem);
  left: $gutter-mobile;
  z-index: 20;
  border: 0.1rem solid var(--black);
  background: rgba(255, 255, 255, 0.95);
  padding: 2rem;
  max-width: calc(100vw - calc(#{$gutter-mobile} * 2) - 7rem);
  opacity: 0;
  visibility: hidden;

  @include rtl() {
    @include switch-ltr(calc(#{$gutter-mobile} + 0.1rem));
  }

  .cc {
    &-link {
      color: var(--red);
      @include underlined-link();
    }

    &-allow {
      @extend .block-button;
      @extend .block-button--slim;
      @extend .block-button--solid-black;
      margin-top: 2px;

      @media screen and (min-width: $portrait) {
          margin-top: 0;
      }
    }

    &-deny {
      @extend .block-button;
      @extend .block-button--slim;
      @extend .block-button--outline-black;
      margin-top: 2rem;
      margin-right: 1rem;
      background: var(--transparent);
    }
  }

  *:focus {
    outline: 0;
  }
}
