.header {
  min-height: 8rem;

  * {
    font-size: 1.3rem;

    @include fonts-loaded() {
      font-family: var(--roboto-medium);
    }
  }

  .inner {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1.8rem;
    padding-bottom: 1.8rem;

    @media screen and (min-width: $nav) {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }

  .icon {
    z-index: 15;

    @media screen and (max-width: $nav - 1) {
      &--umbro {
        position: absolute;
        @include central();
      }

      &--burger {
        margin-left: auto;

        @include rtl() {
          @include switch-mar(0, auto);
        }
      }
    }
  }

  @media screen and (min-width: $nav) {
    min-height: 9.5rem;

    .block-button--shop,
    .nav--primary li {
      margin-right: 3rem;
    }

    .inner {
      justify-content: flex-end;
    }

    .nav {
      &-wrapper {
        flex-grow: 1;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 4.6rem;
      }

      &__search {
        order: 4;
        position: absolute;
        right: 0;
        @include central-y();
        display: none;
        width: 0;
        z-index: 20;
        overflow: hidden;
      }

      &--country {
        order: 3;
        position: relative;
        margin-right: 1rem;
        z-index: 5;

        > a {
          padding-left: 1rem;
        }

        .subnav {
          position: absolute;
          top: 6rem;
          left: 0;
        }
      }

      &--primary {
        order: 1;
        flex-grow: 1;

        ul {
          display: flex;
        }

        a {
          white-space: nowrap;
          padding: 1rem 0;
        }
      }
    }

    .block-button--shop {
      order: 3;
    }

    .icon {
      &--umbro {
        width: 6.8rem;
        margin-right: 5rem;

        &:before {
          background-size: auto 100%;
        }
      }

      &--burger {
        display: none;
      }

      &--search {
        order: 5;
      }
    }
  }

  @media screen and (min-width: $max) {
    .block-button--shop,
    .nav--primary li {
      margin-right: 5rem;
    }

    .icon--umbro {
      margin-right: 6rem;
    }

    .nav--country {
      margin-right: 3rem;
      z-index: 5;
    }
  }

  &--sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateY(-10rem);
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.1);
    min-height: auto;

    .inner {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }

    .icon--umbro {
      height: 4rem;
    }

    .back-button {
      position: relative;
      padding: 0;
      margin: 0;
      top: 0;
      left: 0;
      right: 0;
      color: var(--transparent);
      line-height: unset;
      background: none;

      .icon {
        @include chevron-icon();
      }
    }

    .block-button--shop {
      margin-left: auto;
    }

    @media screen and (max-width: $nav - 1) {
      display: none;
    }

    @media screen and (min-width: $nav) {
      .back-button {
        width: 6.8rem;
        margin-right: 5rem;

        .icon {
          width: 3.8rem;
          height: 3.8rem;
        }
      }

      .block-button--shop {
        margin-right: 0;
      }
    }

    @media screen and (min-width: $max) {
      .back-button + .nav--primary {
        margin-left: 5rem;
      }
    }
  }

  @include rtl() {
    &--sticky {
      .block-button--shop {
        margin-left: 0;
        margin-right: auto;
      }
    }

    @media screen and (max-width: $portrait - 1) {
      .icon--chevron {
        @include switch-ltr(99999);
      }
    }

    @media screen and (min-width: $nav) {
      .icon--umbro {
        @include switch-mar(5, 0);
      }

      .block-button--shop,
      .nav--primary li {
        @include switch-mar(3, 0);
      }

      .nav--country {
        @include switch-mar(1, 0);
      }

      &--sticky {
        .back-button {
          @include switch-mar(5, 0);
        }

        .block-button--shop {
          margin-left: 0;
        }
      }
    }

    @media screen and (min-width: $max) {
      .block-button--shop,
      .nav--primary li {
        @include switch-mar(5, 0);
      }

      .icon--umbro {
        @include switch-mar(6, 0);
      }

      .nav--country {
        @include switch-mar(3, 0);
      }
    }
  }
}
