* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  background-repeat: no-repeat;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
button,
select,
input,
textarea {
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus {
    outline: 0;
  }
}
