.hero {
  @include image-underlay(0.75);

  &--black {
    @include image-underlay(0.75, 'black');
  }
  
  .home & {
    @include image-underlay(1);
  }

  .inner {
    position: relative;
  }

  &__block {
    min-height: 40rem;
    padding: $gutter-mobile 0;
    @include flex-col();

    .back-button ~ & {
      padding: 5.6rem 0;
    }

    * {
      position: relative;
    }

    h1 {
      sup,
      sub {
        font-size: 35%;
      }
    }

    p {
      @include fonts-loaded() {
        font-family: var(--roboto-medium);
      }
    }

    @media screen and (min-width: $portrait) {
      min-height: 50rem;

      .back-button ~ & {
        padding: 8.6rem 0;
        margin-top: -4.4rem;
      }
    }

    @media screen and (min-width: $landscape) {
      min-height: 60rem;
    }
  }

  &--classic,
  &--tall,
  &--bottom-centre {
    .hero__block {
      text-align: center;
    }
  }

  &--bottom {
    &-centre,
    &-left,
    &-right {
      .hero__block {
        justify-content: flex-end;

        h1 {
          font-size: 2.8rem;
          line-height: 1.21;
        }

        p {
          margin-top: 1.5rem;
        }

        .block-button {
          margin-top: 2.5rem;
          @extend .block-button--hero;
        }

        &-bar {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba(0, 0, 0, 0.5);
          padding: 2rem #{$gutter-mobile};

          p,
          .block-button {
            margin-top: 0;
          }

          p {
            display: none;
          }
        }
      }

      &--bottom {
        &-right {
          .hero__block {
            text-align: right;

            h1 {
              text-align: right;
            }
          }
        }
      }

      @media screen and (min-width: $portrait) {
        .inner {
          padding: 0 3rem;
        }

        .hero__block-bar {
          padding: 2rem #{$gutter-portrait};
          justify-content: flex-end;

          p {
            display: block;
            margin-right: 4rem;
            text-align: right;

            @include rtl() {
              @include switch-mar(4, 0);
              text-align: left;
            }
          }
        }
      }

      @media screen and (min-width: $landscape) {
        .inner {
          padding: 0 4rem;
        }

        .hero__block {
          padding: 4rem 0;

          h1 {
            font-size: 4.4rem;
            line-height: 1.14;
          }

          &-bar {
            padding: 2rem #{$gutter-landscape};
          }
        }
      }

      @media screen and (min-width: $desktop) {
        .hero__block-bar {
          padding: 2rem #{$gutter-desktop};
        }
      }
    }

    &-left {
      .hero__block {
        align-items: flex-start;
      }
    }

    &-right {
      .hero__block {
        align-items: flex-end;
      }
    }
  }

  &--classic,
  &--tall {
    .hero__block {
      justify-content: center;

      h1,
      p {
        & + .block-button {
          margin-top: 4rem;
        }
      }

      p {
        font-size: 1.8rem;
        line-height: 1.33;
        margin-top: 2rem;
      }

      @media screen and (min-width: $portrait) {
        padding: $gutter-portrait 0;
      }

      @media screen and (min-width: $landscape) {
        padding: $gutter-landscape 0;

        h1,
        p {
          & + .block-button {
            margin-top: 5rem;
          }
        }

        p {
          margin-top: 3rem;
        }
      }
    }
  }

  &--classic {
    .hero__block {

    }
  }

  &--tall {
    .hero__block {
      min-height: 55rem;

      h1 {
        font-size: 10rem;
        line-height: 1;
      }

      @media screen and (min-width: $portrait) {
        min-height: 65rem;
      }

      @media screen and (min-width: $landscape) {
        min-height: 75rem;

        h1 {
          font-size: 15rem;
        }
      }
    }
  }
}
