.background__video {
  opacity: 0.001;
  @include absolute-cover();
  @include w-c-o();

  &--playing {
    transition: opacity 0.3s ease;
    opacity: 0.999;
  }
}
