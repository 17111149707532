.content {
  padding: 3rem 0 8rem;

  &__header {
    .h1--article {
      @include light-underline();
      margin-bottom: 4rem;
    }
  }

  &__article {
    .timestamp {
      margin: 3rem 0;
    }
  }

  @media screen and (min-width: $portrait) {
    &__header,
    &__article {
      max-width: 84rem;
      margin: 0 auto;
    }
  }

  @media screen and (min-width: $landscape) {
    padding: 5rem 0 12rem;

    &__header {
      .h1--article {
        margin-bottom: 5rem;
      }
    }
  }
}
