.icon {
  @include icon-button();
  flex-shrink: 0;
  position: relative;
  @include pseudo-before();
  opacity: 0.999;

  &--disabled {
    opacity: 0.2;
    pointer-events: none;
  }

  &:before {
    @include central();
    width: 100%;
    height: 100%;
  }

  @include hover() {
    opacity: 0.75;
  }

  &:active {
    transform: scale(0.95);
  }

  &--umbro {
    width: 5.6rem;

    &--black {
      &:before {
        background: url('./../images/global/logo-umbro--black.svg') center center no-repeat;
        background-size: 100% auto;
      }
    }

    &--white {
      &:before {
        background: url('./../images/global/logo-umbro--white.svg') center center no-repeat;
        background-size: 100% auto;
      }
    }
  }

  &--burger {
    margin-right: -1rem;

    @include rtl() {
      @include switch-mar(-1, 0);
    }

    &:before {
      content: none;
    }

    span,
    span:before,
    span:after {
      display: block;
      width: 2.4rem;
      height: 0.2rem;
      background: var(--black);
      position: absolute;
      transition: all 0.3s ease;
      @include w-c-o-t();
    }

    span {
      @include central();

      &:before,
      &:after {
        content: '';
        left: 0;
      }

      &:before {
        top: -0.7rem;
      }

      &:after {
        top: 0.7rem;
      }
    }

    .nav--active--mobile &,
    .nav--active--filter & {
      span {
        background: rgba(0, 0, 0, 0);

        &:before {
          transform: translateY(0.7rem) rotate(45deg);
        }

        &:after {
          transform: translateY(-0.7rem) rotate(-45deg);
        }
      }
    }
  }

  &--search {
    &:before {
      @include w-c-o();
      transition: opacity 0.3s ease-out;
    }

    &:before {
      background: url('./../images/global/icon-search--black.svg') center center no-repeat;
      background-size: 2.5rem auto;
    }
  }

  &--close {
    &:before {
      background: url('./../images/global/icon-close--black.svg') center center no-repeat;
      background-size: 2rem auto;
    }

    &-search:before {
      background: url('./../images/global/icon-close--grey.svg') center center no-repeat;
      background-size: 1.1rem auto;
    }
  }

  &--twitter {
    &--black {
      &:before {
        background: url('./../images/global/logo-twitter--black.svg') center center no-repeat;
        background-size: 2rem auto;
      }
    }

    &--white {
      &:before {
        background: url('./../images/global/logo-twitter--white.svg') center center no-repeat;
        background-size: 2rem auto;
      }
    }
  }

  &--facebook {
    &--black {
      &:before {
        background: url('./../images/global/logo-facebook--black.svg') center center no-repeat;
        background-size: 2rem auto;
      }
    }

    &--white {
      &:before {
        background: url('./../images/global/logo-facebook--white.svg') center center no-repeat;
        background-size: 2rem auto;
      }
    }
  }

  &--instagram {
    &--black {
      &:before {
        background: url('./../images/global/logo-instagram--black.svg') center center no-repeat;
        background-size: 1.9rem auto;
      }
    }

    &--white {
      &:before {
        background: url('./../images/global/logo-instagram--white.svg') center center no-repeat;
        background-size: 1.9rem auto;
      }
    }
  }

  &--youtube {
    &--black {
      &:before {
        background: url('./../images/global/logo-youtube--black.svg') center center no-repeat;
        background-size: 2.1rem auto;
      }
    }

    &--white {
      &:before {
        background: url('./../images/global/logo-youtube--white.svg') center center no-repeat;
        background-size: 2.1rem auto;
      }
    }
  }

  &--whatsapp {
    &--black {
      &:before {
        background: url('./../images/global/logo-whatsapp--black.svg') center center no-repeat;
        background-size: 2rem auto;
      }
    }

    &--white {
      &:before {
        background: url('./../images/global/logo-whatsapp--white.svg') center center no-repeat;
        background-size: 2rem auto;
      }
    }
  }

  &--chevron {
    display: inline-block;
    @include pseudo-after();

    &:before,
    &:after {
      width: 2.5rem;
      height: 2.5rem;
      @include central();
    }

    &:before {
      border: 0.1rem solid var(--white);
      background: var(--transparent);
      transition: all 0.2s ease-out;
      will-change: background-color, transform;
    }

    &:after {
      background: url('./../images/global/icon-chevron--white.svg') center center no-repeat;
      background-size: 0.9rem auto;
      transform: translate(-50%, -50%) rotate(90deg);
    }

    @include hover() {
      opacity: 0.999;

      &:before {
        transition-duration: 0.05s;
        background-color: var(--grey-dark);
        border-color: var(--grey-dark);
      }
    }

    &:active {
      &:before {
        transform: translate(-50%, -50%);
        transition: none;
        background-color: var(--grey-light);
        border-color: var(--grey-light);
      }
    }

    &.back-to-top {
      @include chevron-icon();
      position: fixed;
      bottom: $gutter-mobile;
      right: calc(#{$gutter-mobile} + 0.1rem);
      z-index: 20;
      transform: rotate(90deg);
      background: rgba(255, 255, 255, 0.95);
      opacity: 0;
      visibility: hidden;

      @include rtl() {
        @include switch-rtl(calc(#{$gutter-mobile} + 0.1rem));
      }

      &:active {
        transform: rotate(90deg) scale(0.95);
      }
    }
  }

  &--prev,
  &--next {
    &:before {
      background: url('./../images/global/icon-chevron--black.svg') center center no-repeat;
      background-size: 1.2rem auto;
    }
  }

  &--prev {
    &:before {
      transform: translate(-50%, -50%) rotate(90deg);

      @include rtl() {
        transform: translate(-50%, -50%) rotate(-90deg);
      }
    }
  }

  &--next {
    &:before {
      transform: translate(-50%, -50%) rotate(-90deg);

      @include rtl() {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }
  }

  &--play {
    width: 9rem;
    height: 5rem;
    background: var(--white);

    &:before {
      background: url('./../images/global/icon-play--black.svg') center center no-repeat;
      background-size: 2.3rem auto;
    }

    @include hover() {
      background: var(--grey-dark);
      opacity: 0.999;

      &:before {
        background-image: url('./../images/global/icon-play--white.svg');
      }
    }

    &:active {
      transform: none;
      background: var(--grey-light);
    }
  }
}
